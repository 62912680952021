import { Interpolation, Theme } from '@mui/material'

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    headingXXL: string
    headingXL: string
    headingL: string
    headingM: string
    headingS: string
    headingXS: string
    bodyL: string
    bodyM: string
  }

  interface TypographyOptions {
    headingXXL?: string
    headingXL?: string
    headingL?: string
    headingM?: string
    headingS?: string
    headingXS?: string
    bodyL?: string
    bodyM?: string
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headingXXL: true
    headingXL: true
    headingL: true
    headingM: true
    headingS: true
    headingXS: true
    bodyL: true
    bodyM: true
  }
}

export const typography: Record<
  'headingXXL' | 'headingXL' | 'headingL' | 'headingM' | 'headingS' | 'headingXS' | 'bodyL' | 'bodyM',
  Interpolation<{ theme: Theme }>
> = {
  headingXXL: {
    fontSize: 48,
    fontWeight: 400,
    lineHeight: 1.167,
    letterSpacing: 0,
  },
  headingXL: {
    fontSize: 34,
    fontWeight: 400,
    lineHeight: 1.235,
    letterSpacing: '0.00735em',
  },
  headingL: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: 0,
  },
  headingM: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
  },
  headingS: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.75,
    letterSpacing: '0.00938em',
  },
  headingXS: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    letterSpacing: '0.00714em',
  },
  bodyL: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  bodyM: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },
} as const

export const button: Record<'sizeL' | 'sizeM' | 'sizeS', Interpolation<{ theme: Theme }>> = {
  sizeL: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.03067em',
  },
  sizeM: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.02857em',
  },
  sizeS: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '22px',
    letterSpacing: '0.03538em',
  },
}
