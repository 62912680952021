import { SxProps, Theme } from '@mui/material'
import {
  AnswerEnvironment,
  SatisfactionSurveyKind,
  useAnswerTransactionalSurveyMutation,
  useAnswerTransactionalSurveyReasonMutation,
  useAvailableTransactionalSurveyQuery,
} from 'generated/graphql'
import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { CommonReason, CommonScore, CommonSuccess } from './EnquateCommon'
import { AVAILABLE_SATISFACTION_SURVEY, AVAILABLE_TRANSACTIONAL_SURVEY } from 'constants/local_storage_key'

type Props = {
  // 機能名
  name?: string
  kind: SatisfactionSurveyKind
  sx?: SxProps<Theme>
}
export const revokeTransactionalSurvey = () => {
  Object.keys(localStorage).forEach((key) => {
    if (key.startsWith(AVAILABLE_TRANSACTIONAL_SURVEY)) {
      localStorage.removeItem(key)
    }
  })
}

export const TransactionalSurvey: React.FC<Props> = (props) => {
  const [step, setStep] = useState<'score' | 'reason' | 'success' | 'hidden'>('score')

  const [score, setScore] = useState<number | null>(0)
  const [reason, setReason] = useState('')
  const [reasonId, setReasonId] = useState<string | undefined>(undefined)

  const { data: aData, loading } = useAvailableTransactionalSurveyQuery({
    variables: {
      input: {
        kind: props.kind,
      },
    },
    // キャッシュが効いたら表示確率が変わってしまうので、キャッシュを無効化する
    fetchPolicy: 'network-only',
  })

  const [answerTransactionalSurvey] = useAnswerTransactionalSurveyMutation({
    onCompleted: (data) => {
      const storageId = AVAILABLE_TRANSACTIONAL_SURVEY + props.kind + 'Id'
      localStorage.setItem(storageId, aData?.availableTransactionalSurvey?.id ?? '')
      setReasonId(data.answerTransactionalSurvey?.id)
      // スコアを入力したら、理由を入力するステップに移動
      setStep('reason')
    },
  })
  const [answerTransactionalSurveyReason] = useAnswerTransactionalSurveyReasonMutation({
    onCompleted: () => {
      // 理由を入力したら、完了ステップに移動
      setStep('success')
    },
  })

  // 満足度アンケートが回答されている場合は、表示しない
  // 但し、トランザクショナルアンケートが回答されていても、リレーショナルアンケートは表示される
  const isHidden = useMemo(() => {
    const availablSatisfactionSurveyId = localStorage.getItem(AVAILABLE_SATISFACTION_SURVEY)

    const storageId = AVAILABLE_TRANSACTIONAL_SURVEY + props.kind + 'Id'
    const availableTransactionalSurveyId = localStorage.getItem(storageId)

    return (
      availablSatisfactionSurveyId ||
      availableTransactionalSurveyId ||
      !aData?.availableTransactionalSurvey?.id ||
      // availableTransactionalSurvey.idがlocalStorageに保存しているIDと一致する場合は、表示しない
      availableTransactionalSurveyId === aData.availableTransactionalSurvey.id
    )
  }, [aData])

  const handleSubmit = async () => {
    if (!reasonId) return
    await answerTransactionalSurveyReason({
      variables: {
        input: {
          id: reasonId,
          reason: reason,
        },
      },
    })
  }

  const handleRatingChange = async (_: SyntheticEvent, newValue: number | null) => {
    if (!newValue || !aData?.availableTransactionalSurvey?.id) return

    setScore(newValue)
    await answerTransactionalSurvey({
      variables: {
        input: {
          answerEnvironment: AnswerEnvironment.Web,
          transactionalSurveyId: aData.availableTransactionalSurvey.id,
          score: newValue,
        },
      },
    })
  }

  const handleClose = () => {
    setStep('hidden')
    setReason('')
    setScore(null)
    setReasonId(undefined)
    localStorage.setItem(`availableTransactionalSurvey${props.kind}Id`, aData?.availableTransactionalSurvey?.id ?? '')
  }

  // ページ遷移時に、ステップをリセットする
  useEffect(() => {
    if (aData?.availableTransactionalSurvey?.id) {
      setStep('score')
      setReason('')
      setScore(null)
      setReasonId(undefined)
    }
  }, [aData])

  if (loading || isHidden) return null
  return (
    <>
      {/* おすすめスコア */}
      <CommonScore
        open={step === 'score'}
        sx={props.sx}
        text={`${props.name}機能を知り合いに薦める可能性はどのくらいありますか？`}
        handleClose={handleClose}
        score={score}
        handleRatingChange={handleRatingChange}
      />
      {/* 理由 */}
      <CommonReason
        open={step === 'reason'}
        onSubmit={handleSubmit}
        sx={props.sx}
        setReason={setReason}
        handleClose={handleClose}
        reason={reason}
      />

      {/* お礼 */}
      <CommonSuccess open={step === 'success'} sx={props.sx} handleClose={handleClose} />
    </>
  )
}
