import Bugsnag from '@bugsnag/js'
import { usePartialAuth } from 'contexts/AuthContext'
import { createContext, Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { registerFcmToken } from 'repository/api/UserAdvRepository'
import { firebaseApp } from 'utils/firebase/app'
import { getMessaging, getToken, isSupported, Messaging, onMessage } from 'firebase/messaging'
import { auth } from 'utils/firebase/auth'
import { FIREBASE_CONFIG } from 'utils/config'

const CHANNEL_NAME = 'toridori-marketing-firebase-cloud-messaging-listen'
const UNSUBSCIBE_MESSAGE = 'unsubscribe'
const vapidKey = FIREBASE_CONFIG.fcmWebPushKey

// WevViewで開いた場合に、getMessaging関数内で正常にServiceWorkerにアクセス出来ない場合がある
const getFirebaseMessaging = () => {
  try {
    return getMessaging(firebaseApp)
  } catch (_) {
    return
  }
}

const onRecieveMessage: Parameters<typeof onMessage>[1] = (payload) => {
  if (typeof payload !== 'object') return
  try {
    // 問題: notificationがundefinedになる場合がある（原因不明)
    // 対応: notification未定義の場合は空文字埋め
    const body = payload.notification?.body ?? ''
    const title = payload.notification?.title ?? ''
    const link = payload.fcmOptions?.link ?? ''

    // serviceWorker経由で通知表示
    // 通知クリック時の挙動はpublic/firebase-messaging-sw.jsを参照
    navigator.serviceWorker
      .getRegistration(location.origin + '/firebase-cloud-messaging-push-scope')
      .then((registration) => {
        registration?.showNotification(title, {
          body,
          data: { url: link },
        })
      })
  } catch (e) {
    console.error(e)
    Bugsnag.notify(e as Error)
  }
}

type IWebPush = {
  permission: NotificationPermission
  setPermission: Dispatch<SetStateAction<NotificationPermission>>
}

export const WebpushContext = createContext<IWebPush>({} as never)

export const WebpushProvider: FC<{ children: ReactNode }> = ({ children }) => {
  // Note: アプリのWebViewから参照されたときにundefinedになる
  const [permission, setPermission] = useState<NotificationPermission>(
    typeof Notification === 'undefined' ? 'default' : Notification.permission,
  )
  const { currentUserAdv } = usePartialAuth()

  // 通知が許可された際にトークンの更新と購読を開始する
  useEffect(() => {
    ;(async () => {
      if (!(await isOk())) return

      const firebaseMessaging = getFirebaseMessaging()
      if (!firebaseMessaging) return
      updateFcmToken(firebaseMessaging)

      // NOTE: 複数タブ開いていると、フォアグラウンドで受信した場合にタブ数分だけ通知が表示されてしまう。
      // そのため、タブを開いた際にチャンネルに購読解除のメッセージを送信し、別タブでの購読を解除する。
      // 先にメッセージを送信することで、自身が送信したメッセージは受信せず、別タブからのメッセージのみ購読できる
      const bc = new BroadcastChannel(CHANNEL_NAME)
      bc.postMessage(UNSUBSCIBE_MESSAGE)
      const unsubscribe = onMessage(firebaseMessaging, onRecieveMessage)
      bc.addEventListener('message', (e) => {
        if (e.data === UNSUBSCIBE_MESSAGE) return unsubscribe()
      })
    })()
  }, [permission])

  // Note: Token更新後にUserAdvが作成されるケースを想定 by takase
  useEffect(() => {
    ;(async () => {
      if (!(await isOk())) return
      const firebaseMessaging = getFirebaseMessaging()
      if (!firebaseMessaging) return
      updateFcmToken(firebaseMessaging)
    })()
  }, [currentUserAdv?.id])

  /** WebPushが使える状態であればtrue, そうでなければfalse */
  const isOk = async () => (await isSupported()) && permission === 'granted'

  const updateFcmToken = async (messaging: Messaging) => {
    const token = await auth.getCurrentIdToken()
    if (!token) return
    const fcmToken = await getToken(messaging, { vapidKey })
    if (!fcmToken) return
    registerFcmToken(fcmToken)
  }

  return <WebpushContext.Provider value={{ permission, setPermission }}>{children}</WebpushContext.Provider>
}
