import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'
import CreditCard from '@mui/icons-material/CreditCard'
import PersonIcon from '@mui/icons-material/Person'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useAuth } from 'contexts/AuthContext'
import { useRouter } from 'next/router'
import { MutableRefObject, useRef, useState } from 'react'
import { AdvAccount } from 'types/AdvAccount'
import { UserAdv } from 'types/UserAdv'
import { theme } from 'utils/theme'
import { pagesPath } from 'utils/$path'
import { grey } from '@mui/material/colors'
import { auth } from 'utils/firebase/auth'
import { deleteCustomProperty } from 'utils/techtouch'

export const UserMenu: React.FC = () => {
  const { currentUserAdv, currentAdv } = useAuth()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const ref = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const anchorEl = open ? ref.current : null

  const router = useRouter()
  const handleSignout = () => {
    auth.signOut().then(() => {
      router.push(pagesPath.signin.$url())
    })
  }
  const handleEditPersonalInfo = () => {
    router.push(pagesPath.adv_account.personal_info.$url())
    handleClose()
  }
  const handlePaymentSetting = () => {
    router.push(pagesPath.adv_account.register_payment.$url())
    handleClose()
  }

  const isProxyLogin = currentAdv.isProxied

  const handleChangeProxyLoginAccount = () => {
    deleteCustomProperty()
    router.push(pagesPath.signin.support.$url())
    handleClose()
  }

  return (
    <UserMenuPresenter
      anchorRef={ref}
      anchorEl={anchorEl}
      userAdv={currentUserAdv}
      advAccount={currentAdv}
      avatarOnly={isMobile}
      isProxyLogin={isProxyLogin}
      onSignout={handleSignout}
      onEditPersonalInfo={handleEditPersonalInfo}
      onPaymentSetting={handlePaymentSetting}
      onChangeProxyLoginAccount={handleChangeProxyLoginAccount}
      onOpen={handleOpen}
      onClose={handleClose}
    />
  )
}

type PresenterProps = {
  anchorRef: MutableRefObject<HTMLDivElement | null>
  anchorEl: HTMLElement | null
  userAdv: UserAdv
  advAccount: AdvAccount
  avatarOnly: boolean
  isProxyLogin?: boolean
  onSignout?: () => void
  onEditPersonalInfo?: () => void
  onPaymentSetting?: () => void
  onChangeProxyLoginAccount?: () => void
  onOpen?: () => void
  onClose?: () => void
}

export const UserMenuPresenter: React.FC<PresenterProps> = ({
  anchorRef,
  anchorEl,
  userAdv,
  advAccount,
  avatarOnly,
  isProxyLogin,
  onSignout,
  onEditPersonalInfo,
  onPaymentSetting,
  onChangeProxyLoginAccount,
  onOpen,
  onClose,
}) => (
  <div ref={anchorRef}>
    {avatarOnly ? (
      <IconButton onClick={onOpen}>
        <Avatar sx={{ width: 32, height: 32, bgcolor: grey[600] }} variant={isProxyLogin ? 'rounded' : 'circular'}>
          {isProxyLogin ? <SupportAgentIcon /> : <PersonIcon />}
        </Avatar>
      </IconButton>
    ) : (
      <Chip
        icon={isProxyLogin ? <SupportAgentIcon color="white" /> : <PersonIcon color="white" />}
        label={`${userAdv.name} 様`}
        onClick={onOpen}
        sx={{
          color: 'white',
          borderColor: 'white',
          fontWeight: 'bold',
          ...(isProxyLogin && {
            borderRadius: 1,
          }),
        }}
        variant="outlined"
      />
    )}

    <Popover
      anchorEl={anchorEl}
      disablePortal
      disableScrollLock
      open={!!anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Card sx={{ minWidth: 300, maxWidth: '100%' }}>
        {avatarOnly && (
          <CardContent>
            <Stack gap={1}>
              <Typography variant="caption" color="primary">
                お客様ID {userAdv.id}
              </Typography>
              <Box>
                <Typography>
                  {userAdv.name} 様
                  <Typography variant="body2" color="text.secondary" component="span">
                    （{advAccount.companyName}）
                  </Typography>
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                {userAdv.email}
              </Typography>
            </Stack>
          </CardContent>
        )}

        <Divider />
        <MenuList sx={{ margin: 0 }}>
          <MenuItem onClick={onEditPersonalInfo}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              fontSize="small"
            >
              <Settings fontSize="inherit" sx={{ mr: 1 }} />
              登録情報の確認・変更
            </Typography>
          </MenuItem>
          {
            // 支払い方法が自社請求書以外（MFKESSAI or クレジット)の人だけ出す
            advAccount.paymentType !== 'invoice' && (
              <MenuItem onClick={onPaymentSetting}>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  fontSize="small"
                >
                  <CreditCard fontSize="inherit" sx={{ mr: 1 }} />
                  お支払いに関する設定
                </Typography>
              </MenuItem>
            )
          }
          <MenuItem onClick={onSignout}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              color="error"
              fontSize="small"
            >
              <Logout fontSize="inherit" sx={{ mr: 1 }} />
              ログアウト
            </Typography>
          </MenuItem>
          {isProxyLogin && (
            <MenuItem onClick={onChangeProxyLoginAccount}>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                color="green"
                fontSize="small"
              >
                <ManageAccountsIcon fontSize="inherit" sx={{ mr: 1 }} />
                代理ログイン切り替え
              </Typography>
            </MenuItem>
          )}
        </MenuList>
      </Card>
    </Popover>
  </div>
)
