import { useEffect, useMemo, useState } from 'react'
import { LiteracySurveyOption, useLiteracySurveyAnswerQuery, useTicketSubscriptionsQuery } from 'generated/graphql'
import { customPropertyId, setCustomProperty } from 'utils/techtouch'
import { excludePathes, usePartialAuth } from 'contexts/AuthContext'
import { InvalidTypeError } from 'types/error/InvalidTypeError'
import { useRouter } from 'next/router'

type LiteracyProperty = 'low' | 'mid' | 'high'

type CustomPropertiesSet = Record<(typeof customPropertyId)[keyof typeof customPropertyId], boolean>

function getLiteracyProperty(answer: LiteracySurveyOption): LiteracyProperty {
  switch (answer) {
    case LiteracySurveyOption.Option1:
    case LiteracySurveyOption.Option2:
      return 'low'
    case LiteracySurveyOption.Option3:
      return 'mid'
    case LiteracySurveyOption.Option4:
    case LiteracySurveyOption.Option5:
      return 'high'
    default:
      throw new InvalidTypeError('無効なリテラシー回答タイプです。')
  }
}

/**
 * techtouchのカスタムプロパティを設定するカスタムフックです
 *
 * カスタムプロパティのidはtechtouchの管理画面で設定したものを使います。
 * techtouch.tsに定義されているcustomPropertyIdを使ってください。
 * @see utils/techtouch.ts
 *
 * 設定したカスタムプロパティの確認方法（開発者ツールのコンソールに出力して確認できます。）
    TechtouchAdmin.customProperty.keys().forEach(function (key) {
      console.log(key)
      console.log(TechtouchAdmin.customProperty.get(key))
      console.log(typeof TechtouchAdmin.customProperty.get(key))
    })
 */
export const useSetCustomProperties = () => {
  const [customPropertiesSet, setCustomPropertiesSet] = useState<CustomPropertiesSet>(
    Object.values(customPropertyId).reduce((prev, val) => {
      prev[val] = false
      return prev
    }, {} as CustomPropertiesSet),
  )

  const router = useRouter()

  // 認証ページだったらtrueを返す
  const isAuthPage = useMemo(() => excludePathes.some((path) => router.pathname.startsWith(path)), [router.pathname])

  // 全てのカスタムプロパティがセットされているか確認
  const allPropertiesSet = useMemo(
    () => Object.values(customPropertiesSet).every((value) => value),
    [customPropertiesSet],
  )

  // Graghqlでデータを取得する時にはskipにcustomPropertiesSetの値を渡す(無駄なデータ取得を防ぐ)
  const { data: ticketSubscriptionData } = useTicketSubscriptionsQuery({
    skip: isAuthPage || customPropertiesSet[customPropertyId.canCancelSubscriptionId],
  })
  const { data: literacyAnswerData } = useLiteracySurveyAnswerQuery({
    skip: isAuthPage || customPropertiesSet[customPropertyId.literacyAnswerId],
  })
  // 認証ページでも呼び出すので、usePartialAuthの方を使う
  const { currentAdv } = usePartialAuth()

  const literacySurveyAnswer = literacyAnswerData?.viewerAsAdvertiser.literacySurveyAnswer?.answer
  const advAccountId = currentAdv?.id
  const canCancelSubscription = ticketSubscriptionData?.viewer.canCancelSubscription

  useEffect(() => {
    // 全てのプロパティがセットされているまたは認証ページの場合、早期リターン
    if (allPropertiesSet || isAuthPage) {
      return
    }

    try {
      // テックタッチ側で「テキストが一致している」という条件を使っているのでString型にする必要がある。
      const properties = [
        {
          id: customPropertyId.advAccountIdLabelId,
          value: String(advAccountId),
        },
        {
          id: customPropertyId.canCancelSubscriptionId,
          value: canCancelSubscription === false ? 'false' : canCancelSubscription === true ? 'true' : undefined,
        },
        {
          id: customPropertyId.literacyAnswerId,
          value: literacySurveyAnswer && getLiteracyProperty(literacySurveyAnswer),
        },
      ]

      properties.forEach(({ id, value }) => {
        if (value !== undefined && !customPropertiesSet[id]) {
          setCustomProperty(id, value)
          setCustomPropertiesSet((prevState) => ({ ...prevState, [id]: true }))
        }
      })
    } catch (error) {
      console.error('Failed to set custom properties:', error)
    }
  }, [
    ticketSubscriptionData,
    literacySurveyAnswer,
    advAccountId,
    canCancelSubscription,
    customPropertiesSet,
    allPropertiesSet,
    isAuthPage,
  ])
}
