import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import { WHITE_SPACE_REGEXP } from 'utils/constants'
import { MAX_LENGTH_VALIDATION_MESSAGE, trimStartHash, validate } from '.'

export type SingleTagInputProps = {
  multiple?: false
  name?: string
  value?: string
  errorMessage?: string
  onChange: (value?: string, errorMessage?: string) => void
  disabled?: boolean
  undelietable?: boolean
}

export const SingleTagInput: React.VFC<SingleTagInputProps> = ({
  value,
  onChange,
  errorMessage,
  disabled,
  undelietable = false,
}) => {
  // NOTE: Enterキーで確定のみならこのstateは必要ないが、
  //       追加ボタンの押下でタグ追加したい場合は、
  //       AutocompleteのonChangeで渡ってくる現在の値にアクセスする方法が
  //       他にないので必要になる。
  // NOTE: スマートフォンでEnterキーをハンドリングすることができないため、
  //       追加ボタンが必要
  // NOTE: 理想的にはuseAutocompleteを使用して独自コンポーネントを作りたい
  const [editing, setEditing] = useState('')
  const tagRef = useRef(value ?? '')

  const handleAdd = () => {
    // 未入力の場合は何もしない
    if (!editing) {
      return
    }

    // すでに追加されたタグが存在する場合は上限に達した旨のエラー
    if (tagRef.current) {
      onChange(tagRef.current, MAX_LENGTH_VALIDATION_MESSAGE)
      setEditing('')
      return
    }

    // バリデーション不正のハンドリング
    const validationResult = validate(editing)
    if (validationResult) {
      onChange(tagRef.current, validationResult)
      setEditing('')
      return
    }

    tagRef.current = trimStartHash(editing)
    setEditing('')
    onChange(tagRef.current)
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <Autocomplete
          multiple // 入力確定後にChipの見た目で見せるために、multipleにした上で入力できる数を制限する
          freeSolo
          options={[] as string[]}
          value={value ? [value] : ([] as string[])}
          disableClearable
          disabled={disabled || !!value}
          onBlur={() => {
            if (!editing) return
            // バリデーション不正のハンドリング
            const validationResult = validate(editing)
            if (validationResult) {
              onChange(undefined, validationResult)
              return
            }

            const trimedVal = trimStartHash(editing)
            onChange(trimedVal)
            tagRef.current = trimedVal
            setEditing('')
          }}
          onChange={(_, tags, reason, details) => {
            const detailOption = details?.option
            if (!detailOption) return
            if (reason === 'blur' || reason === 'clear' || reason === 'selectOption') return
            if (reason === 'removeOption') {
              // 削除不可タグのハンドリング
              if (undelietable) {
                return
              } else {
                onChange(undefined)
                tagRef.current = ''
                return
              }
            }

            // 長さのハンドリング
            if (tags.length > 1) {
              onChange(tags[0], MAX_LENGTH_VALIDATION_MESSAGE)
              return
            }

            // バリデーション不正のハンドリング
            const validationResult = validate(detailOption)
            if (validationResult) {
              onChange(undefined, validationResult)
              return
            }

            const trimedVal = trimStartHash(detailOption)
            onChange(trimedVal)
            tagRef.current = trimedVal
          }}
          // FIXME: freeSolo={true}に指定していれば基本的に自動でEnterキーの処理をしてくれるはずだが動かない
          //        stateを多重に抱えてるせいでなんかおかしいことが起こってそう
          // 対応:  とりえあずonKeyDownで'Enter'または' '(スペース)がきたら入力確定させる
          onInputChange={(_event, value) => {
            if (/^\s/.test(value)) return // 先頭がスペースの場合は何もしない
            setEditing(value)
            const lastValue = value.slice(-1)
            if (WHITE_SPACE_REGEXP.test(lastValue)) return handleAdd()
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                // NOTE: editingをTextfieldのvalueに反映させても、
                //       適切に再レンダリングされない場合があるため、
                //       inputのvalueに反映させている
                value: editing,
              }}
              variant="outlined"
              placeholder="Enterかスペースで確定(1個まで)"
              error={!!errorMessage}
            />
          )}
          renderTags={(tags, getTagProps) => (
            <>
              {tags.map((tag, i) => (
                <Chip {...getTagProps({ index: i })} key={i} label={tag} color="primary" disabled={undelietable} />
              ))}
            </>
          )}
          style={{
            flexGrow: 1,
          }}
        />
      </Box>
      <Box height="30px">
        {errorMessage && (
          <Typography variant="caption" color="error">
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
