type surface = {
  primary: string
  hovered: string
  selected: string
  disabled: string
  inputBackground: string
  link: string
  success: string
  error: string
  warning: string
  info: string
  black: string
}

type status = {
  success: string
  error: string
  warning: string
  info: string
}

type border = {
  textField: string
  hover: string
  disabled: string
  divider: string
  selected: string
  error: string
}

declare module '@mui/material/styles' {
  interface Palette {
    white: string
    toridori: string
    backdrop: string

    surface: surface
    status: status
    border: border
  }
  interface PaletteOptions {
    white: string
    toridori: string
    backdrop: string
    surface: surface
    status: status
    border: border
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    white: true
  }
}

export const colorPalette = {
  white: '#FFFFFF',
  toridori: '#003C80',
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.38)',
  },
  secondary: {
    main: '#2E7D32',
    light: '#2E7D32',
    dark: '#2E7D32',
  },
  error: {
    main: '#D32F2F',
    light: '#D32F2F',
    dark: '#D32F2F',
  },
  surface: {
    primary: '#F5F5F5',
    hovered: 'rgba(0, 0, 0, 0.04)',
    selected: 'rgba(25, 118, 210, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    inputBackground: 'rgba(0, 0, 0, 0.06)',
    link: 'rgba(25, 118, 210, 0.08)',
    success: 'rgba(46, 125, 50, 0.04)',
    error: 'rgba(211, 47, 47, 0.04)',
    warning: 'rgba(237, 108, 2, 0.04)',
    info: 'rgba(2, 136, 209, 0.04)',
    black: '#000000',
  },
  status: {
    success: 'rgba(46, 125, 50, 1)',
    error: 'rgba(211, 47, 47, 1)',
    warning: 'rgba(237, 108, 2, 1)',
    info: 'rgba(2, 136, 209, 1)',
  },
  border: {
    textField: 'rgba(0, 0, 0, 0.23)',
    hover: 'rgba(0, 0, 0, 1)',
    disabled: 'rgba(0, 0, 0, 0.23)',
    divider: 'rgba(0, 0, 0, 0.12)',
    selected: 'rgba(25, 118 ,210, 1)',
    error: 'rgba(211, 47, 47, 1)',
  },
  backdrop: 'rgba(0, 0, 0, 0.5)',
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    selected: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
  },
}
