import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, { useEffect, useMemo, useState } from 'react'
import {
  AnswerEnvironment,
  RelationalSurveyVersion,
  useAnswerRelationalSurveyMutation,
  useAvailableRelationalSurveyQuery,
} from 'generated/graphql'
import { AVAILABLE_SATISFACTION_SURVEY } from 'constants/local_storage_key'
import { snackActions } from 'utils/snackbarHelper'
import { Transition } from 'react-transition-group'
import { ratingValues, RatingValueType } from './Rating'
import { trimText } from 'components/campaign_create/input_form/TagInput'

export const revokeSatisfactionSurvey = () => {
  localStorage.removeItem(AVAILABLE_SATISFACTION_SURVEY)
}

const RATING_ARRAY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
const FIRST_HALF_RATING = RATING_ARRAY.slice(0, 5)
const SECOND_HALF_RATING = RATING_ARRAY.slice(5)
const APPEARING_COMPONENT_HEIGHT = 50
const DIALOG_WIDTH = 585
const MAX_LENGTH = 500
const BTN_SIZE = 40

export const SatisfactionSurvey = () => {
  const [rating, setRating] = useState<number | null>(null)

  const handleRatingClick = (value: number) => {
    setRating(value)
  }

  const [isOpen, setIsOpen] = useState(false)
  const handleDialogOpen = () => setIsOpen(true)

  const handleDialogClose = async () => {
    setIsOpen(false)

    // 回答していない場合は、ダイアログを閉じるときにIDを保存する
    if (!rating) {
      localStorage.setItem(AVAILABLE_SATISFACTION_SURVEY, data?.availableRelationalSurvey?.id ?? '')
    }

    // 回答していなくてもダイアログを閉じるとき、ratingがnullでない場合は点数を送信する
    if (!data || !data.availableRelationalSurvey || !rating) return

    await answerRelationalSurvey({
      variables: {
        input: {
          relationalSurveyId: data.availableRelationalSurvey.id,
          answerEnvironment: AnswerEnvironment.Web,
          score: rating,
          reason: message,
          version: RelationalSurveyVersion.V2,
        },
      },
    })
  }

  const [message, setMessage] = useState('')

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputText = event.target.value
    if (inputText.length <= MAX_LENGTH) {
      setMessage(inputText)
    }
  }

  const { data } = useAvailableRelationalSurveyQuery()

  const [answerRelationalSurvey] = useAnswerRelationalSurveyMutation({
    onCompleted: () => {
      localStorage.setItem(AVAILABLE_SATISFACTION_SURVEY, data?.availableRelationalSurvey?.id ?? '')
    },
  })

  const handleSubmit = async () => {
    // handleDialogCloseの関数の中に値を送信する処理が含まれている
    handleDialogClose()
    snackActions.success('アンケートに回答しました。ご協力ありがとうございます。')
  }

  const shouldHiddenDialog = useMemo(() => {
    const availableSatisfactionSurveyId = localStorage.getItem(AVAILABLE_SATISFACTION_SURVEY)

    // availableRelationalSurveyが存在しない場合は、表示するアンケートがないため表示しない
    if (!data?.availableRelationalSurvey) return true

    // availableRelationalSurvey.idがlocalStorageに保存しているIDと一致する場合は表示しない
    return availableSatisfactionSurveyId === data.availableRelationalSurvey.id
  }, [data])

  const isScreenTall = useMediaQuery('(min-height:800px)')

  const getRatingValue = (type: RatingValueType): string | undefined => {
    if (rating) {
      // nullに対しての対応
      return ratingValues[rating]?.[type] as string | undefined
    } else {
      // nullに対しての対応
      return ratingValues.Null?.[type] as string | undefined
    }
  }

  useEffect(() => {
    if (shouldHiddenDialog) return
    handleDialogOpen()
  }, [shouldHiddenDialog])

  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogClose}
      sx={{
        bottom: `calc(50% - ${APPEARING_COMPONENT_HEIGHT})`,
        maxWidth: DIALOG_WIDTH,
        margin: '0 auto',
        // ウインドウの高さが800px以下の場合はダイアログがスクロールするように。
        height: isScreenTall ? 'fit-content' : 'inherit',
      }}
      TransitionComponent={Fade}
    >
      <Stack direction="row" justifyContent="space-between">
        <DialogTitle color="primary.dark" fontSize={16}>
          フィードバックにご協力ください
        </DialogTitle>
        <IconButton color="default" onClick={handleDialogClose}>
          <CloseIcon fontSize="inherit" style={{ marginRight: 10 }} />
        </IconButton>
      </Stack>

      <Divider />

      <DialogContent>
        <Typography variant="body2" fontWeight="bold" textAlign="center" whiteSpace="pre-wrap">
          {trimText(
            `toridori marketingにどれくらい満足していますか？
            10点満点で選択してください`,
          )}
        </Typography>

        <Stack alignItems="center" my={1}>
          {getRatingValue('icon')}
          <Typography variant="body1" fontWeight="bold" textAlign="center">
            {rating} {getRatingValue('text')}
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="center" flexWrap="wrap" gap={1}>
          {[FIRST_HALF_RATING, SECOND_HALF_RATING].map((ratings, index) => (
            <Stack key={index} direction="row" gap={1}>
              {ratings.map((value) => (
                <Button
                  key={value}
                  variant="contained"
                  color="inherit"
                  fullWidth
                  sx={{
                    borderRadius: '100%',
                    minWidth: BTN_SIZE,
                    width: BTN_SIZE,
                    height: BTN_SIZE,
                    aspectRatio: 1,
                    color: 'white',
                    backgroundColor: rating === value ? ratingValues[value]?.backgroundColor : '',
                    '&:focus': {
                      backgroundColor: ratingValues[value]?.backgroundColor,
                    },
                  }}
                  onClick={() => handleRatingClick(value)}
                >
                  {value}
                </Button>
              ))}
            </Stack>
          ))}
        </Stack>

        <Stack my={1.5} direction="row" justifyContent="space-between">
          <Typography variant="body2">とても不満だ</Typography>
          <Typography variant="body2">とても満足だ</Typography>
        </Stack>

        <Transition in={rating !== null} timeout={100} unmountOnExit>
          {(state) => (
            <Stack
              style={{
                transition: `opacity 0.3s ease, transform 0.3s ease`,
                opacity: state === 'entered' ? 1 : 0,
                transform: state === 'entered' ? 'translateY(0)' : 'translateY(-50px)',
              }}
              spacing={2}
              mt={2.5}
            >
              <Typography
                variant="body1"
                color="dark"
                sx={{
                  px: { xs: 0, sm: 6 },
                  fontWeight: '500',
                }}
              >
                {getRatingValue('question')}
              </Typography>
              <Stack gap={1}>
                <Typography variant="body1" color="dark">
                  コメント（任意）
                </Typography>
                <TextField
                  multiline
                  value={message}
                  onChange={handleChange}
                  rows={4}
                  maxRows={4}
                  inputProps={{
                    maxLength: MAX_LENGTH,
                    style: { fontSize: '15px' },
                  }}
                  placeholder={getRatingValue('placeholder')}
                />
              </Stack>
              <Typography variant="body2" color="grey" fontWeight="bold" textAlign="right">
                {message.length}/{MAX_LENGTH}
              </Typography>
            </Stack>
          )}
        </Transition>
      </DialogContent>

      <Divider />

      <Stack direction="row" justifyContent="center" py={1}>
        <DialogActions>
          <Button onClick={handleDialogClose} variant="contained" color="inherit">
            あとで
          </Button>
        </DialogActions>
        <DialogActions>
          <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!rating}>
            回答を送信する
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  )
}
