import { CircularProgress, Stack, Typography } from '@mui/material'
import { staticPath } from 'utils/$path'

export const InflatingAppLoader = () => (
  <Stack
    gap={2}
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',
    }}
    justifyContent="center"
    alignItems="center"
  >
    <img src={staticPath.logo_icon_png} alt="ロゴ" style={{ maxWidth: '200px' }} />
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <CircularProgress size={10} color="inherit" />
      ロード中...
    </Typography>
  </Stack>
)
