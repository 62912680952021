import axios, { AxiosError } from 'axios'
import { PickRequired } from 'types/utils'

// AxiosErrorからresponseのoptionalを外す（isMarketingApiError内でチェック済み）
export type MarketingApiError = PickRequired<AxiosError<MarketingApiErrorBody>, 'response'>

type MarketingApiErrorBody = {
  error: {
    messages: string[]
    code: string
  }
}

export const isMarketingApiError = (e: unknown): e is MarketingApiError => {
  if (!axios.isAxiosError(e) || !e.response?.data) return false
  const data = e.response.data as MarketingApiErrorBody
  return (
    Array.isArray(data.error.messages) && // 配列であることだけ確認
    typeof data.error.code === 'string'
  )
}
