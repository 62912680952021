declare global {
  interface Window {
    TechtouchAdmin?: TechtouchAdmin
  }
}

interface CustomProperty {
  _data: { [key: string]: any }
  get: (key: string) => any
  set: (key: string, value: any) => void
  delete: (key: string) => void
  keys: () => string[]
}

interface TechtouchAdmin {
  customProperty: CustomProperty
}

const customProperty: CustomProperty = {
  _data: {},
  get: function (t: string) {
    return this._data[t]
  },
  set: function (t: string, e: any) {
    this._data[t] = e
  },
  delete: function (t: string) {
    delete this._data[t]
  },
  keys: function () {
    return Object.keys(this._data)
  },
}

const techtouchAdmin: TechtouchAdmin = {
  customProperty: customProperty,
}

// windowオブジェクトにTechtouchAdminを設定
if (typeof window !== 'undefined' && !window.TechtouchAdmin) {
  window.TechtouchAdmin = techtouchAdmin
}

// カスタムプロパティを設定する関数
export const setCustomProperty = (id: string, value: any) => {
  window.TechtouchAdmin?.customProperty.set(id, value)
}

// カスタムプロパティを全て削除する関数
export const deleteCustomProperty = () => {
  if (window.TechtouchAdmin?.customProperty) {
    window.TechtouchAdmin.customProperty.keys().forEach((key) => {
      window.TechtouchAdmin?.customProperty.delete(key)
    })
  } else {
    console.log('Custom properties are not available.')
  }
}

export const customPropertyId = {
  literacyAnswerId: '621281f9-4a18-4a38-b06d-2fc110342e0b',
  advAccountIdLabelId: '24865c52-a86a-4152-a9b9-f78e0636d861',
  canCancelSubscriptionId: '27ecdeab-109a-4c36-990e-a198f8937677',
} as const
