import { Fab } from '@mui/material'
import { staticPath } from 'utils/$path'
import { LINE_LINK } from 'utils/config'

export const ChatSupportButton = () => (
  <Fab
    sx={{
      gap: 1.4,
      backgroundColor: 'white',
      color: '#06c6755',
      fontWeight: 'bold',
    }}
    variant="extended"
    component="a"
    href={LINE_LINK}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={staticPath.line_png} alt="" style={{ height: '50%', objectFit: 'contain' }}></img>
    チャットサポート
  </Fab>
)
