import React, { createContext, useEffect, useState } from 'react'
import { getTerms } from 'repository/api/TermsRepository'

const TermsContext = createContext({ terms: '', privacy: '' })

const TermsProvider = (props: { children: React.ReactNode }) => {
  const [terms, setTerms] = useState<string>('')
  const [privacy, setPrivacy] = useState<string>('')
  useEffect(() => {
    getTerms()
      .then((res) => {
        setTerms(res.termUrl)
        setPrivacy(res.privacyPolicyUrl)
      })
      .catch(() => {
        // Todo エラー表示は考える。
      })
  }, [])

  return (
    <TermsContext.Provider
      value={{
        terms,
        privacy,
      }}
    >
      {props.children}
    </TermsContext.Provider>
  )
}

export { TermsContext, TermsProvider }
