import { useSnackbar } from 'notistack'
import type { OptionsObject, ProviderContext, SnackbarMessage } from 'notistack'

// Note: https://github.com/iamhosseindhv/notistack/issues/30
const InnerSnackbarUtilsConfigurator = (props: { setUseSnackbarRef: (context: ProviderContext) => void }) => {
  props.setUseSnackbarRef(useSnackbar())
  return null
}

let snackbarContext: ProviderContext
const setUseSnackbarRef = (providerContext: ProviderContext) => {
  snackbarContext = providerContext
}

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
}

export const snackActions = {
  success(msg: SnackbarMessage, options?: Omit<OptionsObject, 'variant'>) {
    this.toast(msg, { variant: 'success', ...options })
  },
  warning(msg: SnackbarMessage, options?: Omit<OptionsObject, 'variant'>) {
    this.toast(msg, { variant: 'warning', ...options })
  },
  info(msg: SnackbarMessage, options?: Omit<OptionsObject, 'variant'>) {
    this.toast(msg, { variant: 'info', ...options })
  },
  error(msg: SnackbarMessage, options?: Omit<OptionsObject, 'variant'>) {
    this.toast(msg, { variant: 'error', ...options })
  },
  toast(msg: SnackbarMessage, options?: OptionsObject) {
    snackbarContext.enqueueSnackbar(msg, options)
  },
  dismiss() {
    snackbarContext.closeSnackbar()
  },
}
