import { Components, createTheme, Theme } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { colorPalette as palette } from 'constants/styles/colors'
import { button, typography } from 'constants/styles/typography'

export const MuiTypography: TypographyOptions = {
  fontFamily: `Roboto, 'Noto Sans JP', -apple-system, 'Segoe UI', sans-serif`,
  button: {
    textTransform: 'none',
  },
  overline: {
    textTransform: 'none',
  },
}
export const components: Components<Omit<Theme, 'components'>> = {
  // テキスト系
  MuiTypography: {
    variants: [
      {
        props: { variant: 'h1' },
        style: typography.headingXL,
      },
      {
        props: { variant: 'h2' },
        style: typography.headingL,
      },
      {
        props: { variant: 'h3' },
        style: typography.headingM,
      },
      {
        props: { variant: 'h4' },
        style: typography.headingS,
      },
      {
        props: { variant: 'h5' },
        style: typography.headingXS,
      },
      {
        props: { variant: 'h6' },
        style: typography.headingXS,
      },
      {
        props: { variant: 'body1' },
        style: typography.bodyL,
      },
      {
        props: { variant: 'body2' },
        style: typography.bodyM,
      },
      {
        props: { variant: 'caption' },
        style: {
          fontSize: 12,
          fontWeight: 400,
          lineHeight: 1.43,
          letterSpacing: '0.03333em',
        },
      },
      {
        props: { variant: 'subtitle1' },
        style: typography.headingS,
      },
      {
        props: { variant: 'subtitle2' },
        style: typography.headingXS,
      },
      {
        props: { variant: 'headingXXL' },
        style: typography.headingXXL,
      },
      {
        props: { variant: 'headingXL' },
        style: typography.headingXL,
      },
      {
        props: { variant: 'headingL' },
        style: typography.headingL,
      },
      {
        props: { variant: 'headingM' },
        style: typography.headingM,
      },
      {
        props: { variant: 'headingS' },
        style: typography.headingS,
      },
      {
        props: { variant: 'headingXS' },
        style: typography.headingXS,
      },
      {
        props: { variant: 'bodyL' },
        style: typography.bodyL,
      },
      {
        props: { variant: 'bodyM' },
        style: typography.bodyM,
      },
    ],
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none',
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      size: 'small',
    },
    variants: [
      {
        props: {
          variant: 'outlined',
          size: 'small',
        },
        style: {},
      },
      {
        props: {
          variant: 'filled',
          size: 'small',
        },
        style: {},
      },

      {
        props: {
          variant: 'standard',
          size: 'small',
        },
        style: {},
      },
    ],
  },
  MuiTextField: {
    defaultProps: {
      size: 'small',
    },
    variants: [
      {
        props: {
          variant: 'outlined',
          size: 'small',
        },
        style: {},
      },
      {
        props: {
          variant: 'filled',
          size: 'small',
        },
        style: {},
      },
      {
        props: {
          variant: 'standard',
          size: 'small',
        },
        style: {},
      },
    ],
  },
  // ボタン系
  MuiButton: {
    variants: [
      // カラーによって、ホバーなどのスタイルを変更
      {
        props: { color: 'primary' },
        style: {
          ':disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
        },
      },
      {
        props: { color: 'secondary' },
        style: {
          ':disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
          },
        },
      },
      // サイズによって、ボタンのスタイルを変更
      {
        props: { size: 'small' },
        style: button.sizeS,
      },
      {
        props: { size: 'medium' },
        style: button.sizeM,
      },
      {
        props: { size: 'large' },
        style: button.sizeL,
      },
    ],
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&&.Mui-selected': {
          backgroundColor: palette.action.selected,
        },
        '&&.Mui-selected:hover': {
          backgroundColor: palette.surface.hovered,
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
      },
    },
  },
  MuiCheckbox: { defaultProps: { color: 'primary' } },
  MuiRadio: { defaultProps: { color: 'primary' } },
  MuiSwitch: { defaultProps: { color: 'primary' } },
  // Table系
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontSize: 14,
        fontWeight: 700,
      },
      body: {
        fontSize: 14,
        fontWeight: 400,
      },
    },
  },
  // その他
  MuiAppBar: { defaultProps: { color: 'inherit' } },
  MuiAlert: {
    variants: [
      {
        props: { severity: 'success' },
        style: {
          backgroundColor: palette.surface.success,
        },
      },
      {
        props: { severity: 'error' },
        style: {
          backgroundColor: palette.surface.error,
        },
      },
      {
        props: { severity: 'warning' },
        style: {
          backgroundColor: palette.surface.warning,
        },
      },
      {
        props: { severity: 'info' },
        style: {
          backgroundColor: palette.surface.info,
        },
      },
    ],
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: 1.43,
        letterSpacing: 0,
      },
    },
  },
}
export const theme = createTheme({
  typography: MuiTypography,
  palette,
  components,
})
export const drawerWidth = 240
