import { Box, Typography } from '@mui/material'
import { useAuth } from 'contexts/AuthContext'

export const UserInfo: React.FC = () => {
  const { currentUserAdv, currentAdv } = useAuth()

  return (
    <Box display="flex" alignItems="center" sx={{ gap: '20px', mr: 1 }}>
      <Box display="flex" flexDirection="column">
        <Typography variant="caption" color="white" fontWeight="bold" noWrap>
          {currentAdv.companyName}
        </Typography>
        <Typography variant="caption" color="white" fontWeight="bold" noWrap>
          お客様ID: {currentUserAdv.id}
        </Typography>
      </Box>
    </Box>
  )
}
