import OpenInNew from '@mui/icons-material/OpenInNew'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import HomeIcon from '@mui/icons-material/Home'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import FlagIcon from '@mui/icons-material/Flag'
import CampaignIcon from '@mui/icons-material/Campaign'
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import CodeIcon from '@mui/icons-material/Code'
import { Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Skeleton, Stack } from '@mui/material'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { CreateCampaignButton } from './CreateCampaignButton'
import { pagesPath } from 'utils/$path'
import { ReactNode } from 'react'
import { BeginnerIcon } from 'components/icons'
import dayjs, { Dayjs } from 'dayjs'
import FiberNewIcon from '@mui/icons-material/FiberNew'
import { useViewerQuery } from 'generated/graphql'

type MenuRoute = {
  path: string
  text: string
  Icon: ReactNode
  showNewIconUntil?: Dayjs
}

// color palletから参照しなくてよい
const iconStyle = {
  color: 'rgb(0, 87, 183)',
  fill: 'rgb(0, 87, 183)',
}
const menuRoutes: readonly MenuRoute[] = [
  {
    path: pagesPath.home.$url().pathname,
    text: 'ホーム',
    Icon: <HomeIcon sx={iconStyle} />,
  },
  {
    path: pagesPath.campaigns.$url().pathname,
    text: 'キャンペーン一覧',
    Icon: <CampaignIcon sx={iconStyle} />,
  },
  {
    path: pagesPath.ugc_tags.$url().pathname,
    text: 'UGC',
    Icon: <CodeIcon sx={iconStyle} />,
  },
  {
    path: pagesPath.reports.$url().pathname,
    text: 'レポート',
    Icon: <ShowChartIcon sx={iconStyle} />,
  },
  {
    path: pagesPath.offer_reports.$url().pathname,
    text: '承認した完了報告',
    Icon: <CheckBoxIcon sx={iconStyle} />,
  },
  {
    path: pagesPath.contract.$url().pathname,
    text: 'チケットの購入・契約',
    Icon: <CurrencyYenIcon sx={iconStyle} />,
  },
  {
    path: pagesPath.subscriptions.$url().pathname,
    text: '所持チケット・契約確認',
    Icon: <FlagIcon sx={iconStyle} />,
  },
  {
    path: pagesPath.invoice.$url().pathname,
    text: '各月の請求',
    Icon: <ReceiptLongIcon sx={iconStyle} />,
  },
  {
    path: pagesPath.beginner.instagram.$url().pathname,
    text: 'ビギナー',
    Icon: (
      <BeginnerIcon
        fontSize="small"
        sx={{
          width: '24px',
          height: '20px',
          ...iconStyle,
        }}
      />
    ),
  },
  {
    path: pagesPath.cancellations.subscription.$url().pathname,
    text: '解約について',
    Icon: <DoDisturbIcon sx={iconStyle} />,
  },
] as const

const OuterLinks = [
  {
    path: 'https://toridori.notion.site/505205c7ace74122811a6eb67be12aa9',
    text: '提供メニュー一覧',
  },
  {
    path: 'https://top-marketing.toridori.me/case',
    text: '他社事例紹介',
  },
  {
    path: 'https://support.collatech.net/hc/ja/articles/900007703983',
    text: '禁止事項',
  },
  {
    path: 'https://toridori.notion.site/8f13f239d9d8485c9f115abbea2ac597',
    text: '提携パートナー特典',
  },
  {
    path: 'https://toridori.notion.site/toridori/toridori-marketing-13cf1719e3b54ad18510cb5f69dfae18',
    text: 'クレジットカード決済規定',
  },
  {
    path: 'https://sites.google.com/toridori.co.jp/likespage/%E3%83%9B%E3%83%BC%E3%83%A0',
    text: 'likesのお申し込みはこちら',
  },
] as const

export const AppDrawerContent = () => {
  const { asPath } = useRouter()

  // TODO: AuthContextなどの層で、useViewerQueryを使用してページレンダリングが終わったタイミングで、data.viewer.useUgcが存在するようにしたい。
  // それなら、スケルトンローディングを使わなくて済む。
  const { data, loading } = useViewerQuery()
  const ugc = data?.viewer.useUgc
  const menu = ugc ? menuRoutes : menuRoutes.filter((r) => r.path !== pagesPath.ugc_tags.$url().pathname)

  return (
    <Stack height="100%">
      <CreateCampaignButton />
      <MenuList sx={{ pt: 0 }}>
        {loading ? (
          <Stack height="100%">
            {menuRoutes.map(({ path }) => (
              <Skeleton variant="rectangular" width={'90%'} height={50} key={path} sx={{ mx: 'auto', mt: 1 }} />
            ))}
          </Stack>
        ) : (
          menu.map(({ path, text, Icon, showNewIconUntil }, i) => (
            <NextLink href={path} passHref key={path} legacyBehavior>
              <MenuItem
                // 指定しないとなぜかtab keyによるfocusが当たらない
                tabIndex={i === 0 ? 0 : undefined}
                key={path}
                focusRipple
                component="a"
                selected={asPath.startsWith(path)}
                sx={{ height: 50, position: 'relative' }}
              >
                <ListItemIcon color="primary">{Icon}</ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: 'small',
                    fontWeight: 'bold',
                  }}
                >
                  {text}
                </ListItemText>
                {showNewIconUntil && dayjs().isBefore(showNewIconUntil) && (
                  <FiberNewIcon
                    sx={{
                      position: 'absolute',
                      right: 10,
                      color: 'error.main',
                      fontSize: 30,
                    }}
                  />
                )}
              </MenuItem>
            </NextLink>
          ))
        )}
      </MenuList>
      <Divider />
      <MenuList>
        {OuterLinks.map(({ path, text }) => (
          <MenuItem
            focusRipple
            component="a"
            href={path}
            key={path}
            target="_blank"
            rel="noreferrer"
            sx={{ height: 40 }}
          >
            <ListItemText primaryTypographyProps={{ fontSize: 'small' }}>{text}</ListItemText>
            <ListItemIcon>
              <OpenInNew style={{ fontSize: 'small' }} />
            </ListItemIcon>
          </MenuItem>
        ))}
      </MenuList>
    </Stack>
  )
}
