import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import LogRocket from 'logrocket'
import { isApp } from './platform'
import { APP_ENV, BUGSNAG_API_KEY } from './config'

/**
 * 除外したいエラーメッセージの一覧
 * （エラークラスによる除外はbugsnagのWebコンソール上で行う）
 */
const WHITE_MESSAGE_LIST = ['ResizeObserver loop limit exceeded', 'NetworkError', 'Failed to fetch']

const getAppType = () => {
  if (typeof window === 'undefined') {
    return 'server'
  }
  return isApp() ? 'webview' : 'browser'
}

// 多重実行しても動作に影響はないがコンソールが警告で埋まるので抑制する
let initialized = false

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- initialized変数の誤検出
if (!initialized) {
  initialized = true

  Bugsnag.start({
    appType: getAppType(),
    releaseStage: APP_ENV,
    enabledReleaseStages: ['production', 'staging'],
    apiKey: BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    onError: (event) => {
      if (event.originalError instanceof Error) {
        if (WHITE_MESSAGE_LIST.some((m) => event.originalError.message.includes(m))) {
          return false
        }
      }

      event.addMetadata('LogRocket', {
        sessionURL: LogRocket.sessionURL,
      })
      event.addMetadata('SourceVersion', {
        buildDateTime: process.env.NEXT_PUBLIC_BUILD_DATE_TIME,
        buildRevision: process.env.NEXT_PUBLIC_BUILD_REVISION,
      })
    },
  })
}
export default Bugsnag
