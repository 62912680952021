import { auth } from 'utils/firebase/auth'
import { PF_TOKEN_LOCAL_STORAGE_KEY } from 'constants/local_storage_key'
import { proxyLogin } from 'utils/proxyLogin'
import { GRAPHQL_API_HOST } from 'utils/config'

type Options = {
  refresh: boolean
}

/**
 * toridori-marketing-api（GraphQLサーバー）認証用のトークンを取得する
 * firebase/authで認証済みのトークンを用いてtoridori-marketing-apiのauthエンドポイントを叩き、
 * 帰ってきたBearerトークンをlocalStorageに保存する
 * localStorageに保存されたトークンがある場合はそれを優先的に使う
 * （refreshオプションを指定することで、localStorageに保存されたトークンを破棄する）
 */
export const getPfToken = async (options?: Options) => {
  const { refresh } = options || {}

  if (!refresh) {
    const pfToken = localStorage.getItem(PF_TOKEN_LOCAL_STORAGE_KEY)
    if (pfToken) return pfToken
  }
  const marketingToken = await auth.getCurrentIdToken()
  const headers: HeadersInit = {
    'Access-Token': `Bearer ${marketingToken}`,
    Service: 'marketing',
  }
  // supportLoginにidが存在する場合は代理ログインなのでヘッダに情報を追加する
  if (proxyLogin.isProxyLogin()) {
    headers['company-id'] = proxyLogin.getProxyAdvAccountId().toString() || ''
  }
  const res = await fetch(GRAPHQL_API_HOST + '/auth', {
    method: 'POST',
    headers,
  })

  const pfTokenBody = await res.json()
  const newPfToken = pfTokenBody.token as string
  if (!newPfToken) throw new Error('authorization failed')
  localStorage.setItem(PF_TOKEN_LOCAL_STORAGE_KEY, newPfToken)
  return newPfToken
}
