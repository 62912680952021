import { AppLayout } from 'components/layout/AppLayout'
import { AuthProvider } from 'contexts/AuthContext'
import { TermsProvider } from 'contexts/TermsContext'
import { LicenseInfo } from '@mui/x-license'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { ReactElement, ReactNode } from 'react'
import { AppApolloProvider } from 'components/provider/AppApolloProvider'
import { useReloadAfterOneDay } from 'hooks/app/useReloadAfterOneDay'
import { useTagManagerInit } from 'hooks/app/useTagManagerInit'
import { useLogRocketInit } from 'hooks/app/useLogRocketInit'
import { BugsnagErrorBoundary } from 'components/error_boundary/BugsnagErrorBoundary'
import { AppSnackbarProvider } from 'components/provider/AppSnackbarProvider'
import { AppThemeProvider } from 'components/provider/AppThemeProvider'
import { FacebookSDK } from 'components/common/Facebook/FacebookSDK'
import { TopProgress } from 'components/TopProgress'
import { CustomErrorPage } from 'components/common/CustomErrorPage'
import { UNDER_MAINTENANCE } from 'constants/maintenance'
import { WebpushProvider } from 'contexts/WebpushContext'
import { LiteracySurvey } from 'components/common/Enquate/LiteracySurvey'
import { APP_ENV, MUI_LICENSE_KEY } from 'utils/config'
import Script from 'next/script'

// 開発環境の場合はmswを読み込む
if (APP_ENV === 'development') require('../mocks/msw')
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY)

// Provider類を全てネストさせたコンポーネント
const providers = [
  BugsnagErrorBoundary,
  AppApolloProvider,
  TermsProvider,
  AppSnackbarProvider,
  AuthProvider,
  WebpushProvider,
  AppThemeProvider,
]

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const CompositeProvider = ({ children }: { children: ReactNode }) => (
  <>
    {providers.reduceRight(
      (children, Provider) => (
        <Provider>{children}</Provider>
      ),
      children,
    )}
  </>
)

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  useReloadAfterOneDay()
  useTagManagerInit()
  useLogRocketInit()

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (UNDER_MAINTENANCE) {
    return (
      <>
        <Head>
          <title>システムメンテナンス中 | toridori marketing</title>
        </Head>
        <CustomErrorPage
          errorTitle="現在システムメンテナンス中のため、サービスをご利用いただけません。"
          errorDetail={'システムメンテナンスが終了次第、再度ご利用ください。'}
          showTopLink={false}
        />
      </>
    )
  }

  /**
   * NOTE: Pagesコンポーネントにて、LayoutプロパティにAppLayout以外のレイアウトを指定することで、
   *        レイアウトを変更することができる。
   *        現状、AppLayoutを外したいユースケースにて、LayoutWithNothingコンポーネントを指定することで
   *        サイドメニューやヘッダの必要ないログイン画面等を標示している。
   *        なお、明示的にLayoutプロパティを指定しなかった場合は、AppLayoutが適用される。
   */
  const Layout = (Component as { Layout?: React.ComponentType }).Layout || AppLayout

  // https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts#with-typescript
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <Head>
        <title>toridori marketing</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>

      <FacebookSDK />
      <CompositeProvider>
        <TopProgress />
        <LiteracySurvey />
        <Layout>{getLayout(<Component {...pageProps} />)}</Layout>
        <TopProgress />
      </CompositeProvider>
      <Script id="techtouch" strategy="lazyOnload">
        {techtouchScript}
      </Script>
    </>
  )
}

const techtouchScript = `
!function () {
  // iframe内で動作させる場合は以下の1行を削除もしくはコメントアウトしてください
  try { if (window.top !== window.self) return; } catch(e) { return; }
  if ('TechtouchObject' in window && document.querySelector('script#techtouch-snippet')) return;
  if ('TechtouchAddon' in window) return;
  window.TechtouchObject = {
    organizationUuid: "orga-65f00809-63bb-f37c-1cc5-b88deb826f70"
  };
  var e = document.createElement("script"); e.async = 1, e.src = "https://apps.techtouch.jp/script/orga-65f00809-63bb-f37c-1cc5-b88deb826f70/main.js?projectUuid=proj-65f3b3bb-fe8b-a088-1530-5f04dc14df91"; e.id = "techtouch-snippet";
  var t = document.getElementsByTagName("script")[0]; t.parentNode.insertBefore(e, t);
}()
`
