import React, { useState } from 'react'
import { Box, Button, Card, Dialog, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import {
  AnsweredLiteracySurveyDocument,
  LiteracySurveyOption,
  useAnsweredLiteracySurveyQuery,
  useAnswerLiteracySurveyMutation,
  useLiteracySurveysQuery,
} from 'generated/graphql'
import { useRouter } from 'next/router'
import { snackActions } from 'utils/snackbarHelper'
import { proxyLogin } from 'utils/proxyLogin'
import { excludePathes } from 'contexts/AuthContext'
import { pagesPath } from 'utils/$path'
import { ErrorAlert } from '../ErrorAlert'

export const LiteracySurvey = () => {
  const router = useRouter()
  // 認証不要ページはスキップ
  const isExcludePath = excludePathes.some((path) => router.pathname.startsWith(path))

  const [surveyMutation, { error: mutationError }] = useAnswerLiteracySurveyMutation()

  const {
    data: isAnswerSurvey,
    loading: isAnswerSurveyLoading,
    error: isAnswerSurveyError,
  } = useAnsweredLiteracySurveyQuery({
    // 認証不要ページはスキップ
    skip: isExcludePath || proxyLogin.isProxyLogin(),
  })
  const {
    data: surveyContents,
    loading: surveyContentsLoading,
    error: surveyContentsError,
  } = useLiteracySurveysQuery({
    // 認証不要ページはスキップ
    skip: isExcludePath || proxyLogin.isProxyLogin(),
  })

  const isAnswered = isAnswerSurvey?.viewerAsAdvertiser.answeredLiteracySurvey ?? false

  const loading = isAnswerSurveyLoading || surveyContentsLoading
  const error = isAnswerSurveyError || surveyContentsError

  const [selectedOption, setSelectedOption] = useState<LiteracySurveyOption | null>(null)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value as LiteracySurveyOption)
  }

  const handleButtonClick = async () => {
    if (selectedOption) {
      surveyMutation({
        variables: {
          input: {
            answer: selectedOption,
          },
        },
        onCompleted: () => {
          snackActions.success('回答ありがとうございました')
        },
        refetchQueries: [AnsweredLiteracySurveyDocument],
      })
    }
  }

  if (
    isExcludePath ||
    // アカウント作成後の案内ページはスキップ
    pagesPath.register_complete.$url().pathname === router.pathname ||
    proxyLogin.isProxyLogin()
  )
    return null

  if (loading) return null

  // アンケート回答済みを取得できないか、アンケート内容が取得できなければ表示しない
  if (error) return null

  return (
    <Dialog open={!isAnswered} maxWidth="lg" fullScreen>
      <Box py={{ md: 7, xs: 3 }} px={{ md: 4, xs: 2 }} sx={{ wordBreak: 'auto-phrase' }}>
        {mutationError && (
          <>
            <ErrorAlert title="アンケートの送信に失敗しました。リロードしてください。" error={mutationError} />
          </>
        )}
        <FormControl>
          <Typography sx={{ fontSize: 20 }} color="primary.main">
            普段の広告の実施方法や求めるものについてもっとも近いと思うものを選んでください
            <Typography
              color="error"
              variant="caption"
              sx={{
                verticalAlign: 'top',
              }}
            >
              *
            </Typography>
          </Typography>
          <RadioGroup name="radio-buttons-group" value={selectedOption} onChange={handleChange} sx={{ my: 4, gap: 2 }}>
            {surveyContents?.literacySurveys.map((survey) => (
              <FormControlLabel
                key={survey.literacySurveyOption}
                value={survey.literacySurveyOption}
                control={<Radio />}
                label={survey.label}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={handleButtonClick} disabled={!selectedOption}>
            送信
          </Button>
        </Box>
        <Card
          sx={{
            mt: 4,
            p: 3,
            backgroundColor: 'whitesmoke',
          }}
          variant="outlined"
        >
          <Typography>
            現在toridori Marketingの機能をご利用いただくには、このアンケートにご協力いただく必要がございます。
            回答結果は今後のサポート体制や機能改善に活用いたします。
          </Typography>
        </Card>
      </Box>
    </Dialog>
  )
}
