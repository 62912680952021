import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { camelizeKeys, snakizeKeys } from 'utils/apiHelper'
import { API_HOST } from 'utils/config'
import { auth } from 'utils/firebase/auth'

const client = axios.create({
  baseURL: API_HOST,
  timeout: 30 * 1000,
  // 代理ログインのためにcross-originにもクッキーを送信する
  withCredentials: true,
})

type RequestConfig = AxiosRequestConfig & {
  url: string
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
}

export async function ApiRequest<T>(requestConfig: RequestConfig) {
  const response = await ApiRequestWholeResponse<T>(requestConfig)
  return response.data
}

async function ApiRequestWholeResponse<T>(requestConfig: RequestConfig): Promise<AxiosResponse<T>> {
  const method = requestConfig.method ?? 'GET'
  const url = requestConfig.url

  const idToken = await auth.getCurrentIdToken()
  const headers = { Authorization: `Bearer ${idToken}` }

  const config: Partial<AxiosRequestConfig> = {
    ...requestConfig,
    method,
    data: snakizeKeys(requestConfig.data),
    headers: { ...headers, ...requestConfig.headers },
    params: snakizeKeys(requestConfig.params),
  }

  try {
    const response = await client.request(config)

    console.debug(`API: ${method} ${API_HOST}${url} ${response.status}`)

    return { ...response, data: camelizeKeys(response.data) as T }
  } catch (e) {
    console.warn(`error occured in request to ${config.url}, params:${JSON.stringify(config.params)}`)
    throw e
  }
}
