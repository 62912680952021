import { Box, Button, Fade, IconButton, Paper, Rating, SxProps, TextField, Theme, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { FC, SyntheticEvent } from 'react'

type FadeBoxProps = {
  open: boolean
  sx?: SxProps<Theme> | undefined
  children: React.ReactNode
  onEntered?: () => void
}
const FadeBox: FC<FadeBoxProps> = ({ open, onEntered, sx, children }) => {
  return (
    <Fade in={open} timeout={300} onEntered={onEntered}>
      <Box
        sx={{
          position: 'fixed',
          top: 80,
          left: 0,
          right: 0,
          zIndex: 12,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Fade>
  )
}

type CommonScoreProps = {
  open: boolean
  text: string
  sx?: SxProps<Theme> | undefined
  handleClose: () => void
  score: number | null | undefined
  handleRatingChange: (event: SyntheticEvent, value: number | null) => Promise<void>
}
export const CommonScore: FC<CommonScoreProps> = ({ text, open, sx, handleClose, score, handleRatingChange }) => {
  return (
    <FadeBox
      open={open}
      sx={{
        p: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Paper
        sx={{
          p: 4,
          display: {
            xs: 'block',
            md: 'flex',
          },
          position: 'relative',
        }}
      >
        <IconButton
          aria-label="close"
          size="small"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>

        <Typography
          sx={{
            fontWeight: 'bold',
          }}
        >
          {text}
        </Typography>
        <Box
          sx={{
            display: {
              xs: 'flex',
              md: 'block',
            },
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Rating name="rating" value={score} max={10} onChange={handleRatingChange} />
        </Box>
      </Paper>
    </FadeBox>
  )
}

type CommonReasonProps = {
  open: boolean
  sx?: SxProps<Theme> | undefined
  handleClose: () => void
  onSubmit: () => void
  setReason: (reason: string) => void
  reason: string
}
export const CommonReason: FC<CommonReasonProps> = ({ open, sx, handleClose, onSubmit, setReason, reason }) => {
  return (
    <FadeBox
      open={open}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Paper
        sx={{
          p: 2,
          position: 'relative',
          width: '50%',
        }}
      >
        <IconButton aria-label="close" size="small" onClick={handleClose} sx={{ mb: 2, ml: 'auto', display: 'block' }}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
        <TextField
          fullWidth
          label="理由(任意)"
          multiline
          rows={2}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
        <Button
          variant="contained"
          sx={{
            mt: 2,
            ml: 'auto',
            display: 'block',
          }}
          onClick={onSubmit}
        >
          送信
        </Button>
      </Paper>
    </FadeBox>
  )
}

type CommonSuccessProps = {
  open: boolean
  sx?: SxProps<Theme> | undefined
  handleClose: () => void
}
export const CommonSuccess: FC<CommonSuccessProps> = ({ open, sx, handleClose }) => {
  return (
    <FadeBox
      open={open}
      sx={{
        p: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
      onEntered={() => {
        setTimeout(() => {
          handleClose()
        }, 3000)
      }}
    >
      <Paper
        sx={{
          p: 4,
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
          }}
        >
          この度はアンケートにご協力いただき、誠にありがとうございました。
        </Typography>

        <Typography
          sx={{
            fontWeight: 'bold',
          }}
        >
          皆様からの貴重なご意見は、今後の改善に役立てさせていただきます。
        </Typography>
      </Paper>
    </FadeBox>
  )
}
