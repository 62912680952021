import { SxProps, Theme } from '@mui/material'
import { SatisfactionSurveyKind } from 'generated/graphql'
import { FC } from 'react'
import { useRouter } from 'next/router'
import { pagesPath } from 'utils/$path'
import { SatisfactionSurvey } from './SatisfactionSurvey'
import { TransactionalSurvey } from './TransactionalSurvey'

type Props = {
  sx: SxProps<Theme> | undefined
}
const includePath = [
  {
    name: 'ホーム',
    path: pagesPath.home.$url().pathname,
    type: SatisfactionSurveyKind.Home,
  },
  {
    name: 'レポート',
    path: pagesPath.reports.$url().pathname,
    type: SatisfactionSurveyKind.Report,
  },
  {
    name: '完了報告一覧',
    path: pagesPath.offer_reports.$url().pathname,
    type: SatisfactionSurveyKind.OfferCompletionList,
  },
  {
    name: 'チケットの購入・契約',
    path: pagesPath.contract.$url().pathname,
    type: SatisfactionSurveyKind.SubscriptionContract,
  },
  {
    name: 'やりとり詳細',
    path: pagesPath.offers._offerId(1).$url().pathname,
    type: SatisfactionSurveyKind.OfferTimeline,
  },
  {
    name: '応募一覧',
    path: pagesPath.campaigns._campaignId(1).campaign_entries.$url().pathname,
    type: SatisfactionSurveyKind.InfluencerEntryList,
  },
]

export const Enquate: FC<Props> = (props) => {
  const router = useRouter()
  return (
    <>
      {/* 満足度アンケート */}
      {router.pathname.startsWith(pagesPath.home.$url().pathname) && <SatisfactionSurvey />}

      {/* トランザクショナルアンケート */}
      {includePath.find((path) => path.path === router.pathname) && (
        <TransactionalSurvey
          sx={props.sx}
          name={includePath.find((path) => path.path === router.pathname)?.name}
          kind={includePath.find((path) => path.path === router.pathname)?.type as SatisfactionSurveyKind}
        />
      )}
    </>
  )
}
