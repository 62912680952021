import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'

const ICON_SIZE = 120

type RatingValue = {
  icon: React.ReactNode
  text: string
  question?: string
  placeholder?: string
  backgroundColor?: string
}

export type RatingValueType = 'icon' | 'text' | 'question' | 'placeholder' | 'backgroundColor'

const RatingText = {
  Null: '選択してください',
  VeryDissatisfied: 'とても不満だ',
  Dissatisfied: '不満だ',
  SomewhatDissatisfied: 'どちらかというと満足していない',
  Neutral: 'どちらともいえない',
  SomewhatSatisfied: 'どちらかというと満足している',
  Satisfied: '満足だ',
  VerySatisfied: 'とても満足だ',
} as const

const RatingQuestion = {
  Dissatisfied: 'ご期待に沿えず申し訳ありません。今後のサービス向上のため、ご意見をお聞かせいただけますか？',
  Neutral: 'フィードバックありがとうございます。今後のサービス向上のため、ご意見をお聞かせいただけますか？',
  Satisfied: '高評価ありがとうございます。今後のサービス向上のため、ご意見をお聞かせいただけますか？',
  VerySatisfied: '高評価ありがとうございます。どのような点が良かったのか、ご意見をお聞かせいただけますか？',
} as const

const RatingPlaceholder = {
  Dissatisfied: '不便な点や改善点など',
  Neutral: '改善点やご要望など',
  Satisfied: '良かった点など',
} as const

const RatingColor = {
  Null: 'disabled',
  Dissatisfied: 'red',
  Neutral: 'orange',
  Satisfied: '#dcd100',
  VerySatisfied: 'green',
} as const

export const ratingValues: { [key: string]: RatingValue } = {
  Null: {
    icon: <SentimentNeutralIcon color={RatingColor.Null} sx={{ fontSize: ICON_SIZE }} />,
    text: RatingText.Null,
    question: undefined,
    placeholder: undefined,
    backgroundColor: undefined,
  },
  1: {
    icon: <SentimentVeryDissatisfiedIcon sx={{ color: RatingColor.Dissatisfied, fontSize: ICON_SIZE }} />,
    text: RatingText.VeryDissatisfied,
    question: RatingQuestion.Dissatisfied,
    placeholder: RatingPlaceholder.Dissatisfied,
    backgroundColor: RatingColor.Dissatisfied,
  },
  2: {
    icon: <SentimentVeryDissatisfiedIcon sx={{ color: RatingColor.Dissatisfied, fontSize: ICON_SIZE }} />,
    text: RatingText.Dissatisfied,
    question: RatingQuestion.Dissatisfied,
    placeholder: RatingPlaceholder.Dissatisfied,
    backgroundColor: RatingColor.Dissatisfied,
  },
  3: {
    icon: <SentimentVeryDissatisfiedIcon sx={{ color: RatingColor.Dissatisfied, fontSize: ICON_SIZE }} />,
    text: RatingText.Dissatisfied,
    question: RatingQuestion.Dissatisfied,
    placeholder: RatingPlaceholder.Dissatisfied,
    backgroundColor: RatingColor.Dissatisfied,
  },
  4: {
    icon: <SentimentNeutralIcon sx={{ color: RatingColor.Neutral, fontSize: ICON_SIZE }} />,
    text: RatingText.SomewhatDissatisfied,
    question: RatingQuestion.Dissatisfied,
    placeholder: RatingPlaceholder.Neutral,
    backgroundColor: RatingColor.Neutral,
  },
  5: {
    icon: <SentimentNeutralIcon sx={{ color: RatingColor.Neutral, fontSize: ICON_SIZE }} />,
    text: RatingText.Neutral,
    question: RatingQuestion.Neutral,
    placeholder: RatingPlaceholder.Neutral,
    backgroundColor: RatingColor.Neutral,
  },
  6: {
    icon: <SentimentNeutralIcon sx={{ color: RatingColor.Neutral, fontSize: ICON_SIZE }} />,
    text: RatingText.SomewhatSatisfied,
    question: RatingQuestion.Neutral,
    placeholder: RatingPlaceholder.Neutral,
    backgroundColor: RatingColor.Neutral,
  },
  7: {
    icon: <SentimentSatisfiedAltIcon sx={{ color: RatingColor.Satisfied, fontSize: ICON_SIZE }} />,
    text: RatingText.Satisfied,
    question: RatingQuestion.Satisfied,
    placeholder: RatingPlaceholder.Satisfied,
    backgroundColor: RatingColor.Satisfied,
  },
  8: {
    icon: <SentimentSatisfiedAltIcon sx={{ color: RatingColor.Satisfied, fontSize: ICON_SIZE }} />,
    text: RatingText.Satisfied,
    question: RatingQuestion.Satisfied,
    placeholder: RatingPlaceholder.Satisfied,
    backgroundColor: RatingColor.Satisfied,
  },
  9: {
    icon: <SentimentVerySatisfiedIcon sx={{ color: RatingColor.VerySatisfied, fontSize: ICON_SIZE }} />,
    text: RatingText.VerySatisfied,
    question: RatingQuestion.VerySatisfied,
    placeholder: RatingPlaceholder.Satisfied,
    backgroundColor: RatingColor.VerySatisfied,
  },
  10: {
    icon: <SentimentVerySatisfiedIcon sx={{ color: RatingColor.VerySatisfied, fontSize: ICON_SIZE }} />,
    text: RatingText.VerySatisfied,
    question: RatingQuestion.VerySatisfied,
    placeholder: RatingPlaceholder.Satisfied,
    backgroundColor: RatingColor.VerySatisfied,
  },
}
