import { UserAdv } from 'types/UserAdv'
import { ApiRequest } from '../client'

export const getCurrentUserAdv = async () => {
  const { userAdv } = await ApiRequest<{ userAdv: UserAdv }>({
    url: '/v1/user_advs/me',
  })
  return userAdv
}

export const registerFcmToken = async (token: string) => {
  // do not check response
  await ApiRequest<void>({
    url: '/v1/user_advs/me/devices',
    method: 'POST',
    data: { token: token },
  })
}

export const preSignUp = async (email: string) => {
  await ApiRequest<void>({
    method: 'POST',
    url: '/v1/user_advs/pre_sign_up',
    data: { email: email },
  })
}

export const preSignUpComplete = async (token: string) => {
  const { email } = await ApiRequest<{ email: string }>({
    method: 'DELETE',
    url: '/v1/user_advs/pre_sign_up',
    data: { token: token },
  })
  return email
}

type CreateUserAdvProps = {
  tel: string
  name: string
  nameFurigana?: string
}

export const createUserAdv = async (payload: CreateUserAdvProps) => {
  // on server-side, user adv info is fetch from firebase
  const { userAdv } = await ApiRequest<{ userAdv: UserAdv }>({
    method: 'POST',
    url: '/v1/user_advs',
    data: {
      ...payload,
    },
  })
  return userAdv
}

export const updateUserAdv = async (userAdvId: number | null | undefined, data: Record<string, unknown>) => {
  // on server-side, user adv info is fetch from firebase
  const { userAdv } = await ApiRequest<{ userAdv: UserAdv }>({
    method: 'PATCH',
    url: `/v1/user_advs/${userAdvId}`,
    data: {
      user_adv: data,
    },
  })
  return userAdv
}
