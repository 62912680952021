/**
 * ENV
 */

export const APP_ENV = (process.env.NEXT_PUBLIC_APP_ENV || 'development') as 'development' | 'staging' | 'production'
export const IS_PRODUCTION = APP_ENV === 'production'

export const IS_STAGING = APP_ENV === 'staging'

/** e.g. https://marketing-api.toridori.me */
let API_HOST: string
/** e.g. https://marketing.toridori.me */
let FRONT_HOST: string
/** e.g. https://top-marketing.toridori.me */
let USER_FRONT_HOST: string
/** e.g. https://toridori-marketing-api.toridori.me */
let GRAPHQL_API_HOST: string
/** e.g. https://marketing-portal.toridori.me */
let PORTAL_HOST: string

switch (process.env.NEXT_PUBLIC_APP_ENV) {
  case 'production':
    API_HOST = 'https://marketing-api.toridori.me'
    FRONT_HOST = 'https://marketing.toridori.me'
    USER_FRONT_HOST = 'https://top-marketing.toridori.me'
    GRAPHQL_API_HOST = 'https://pf-api.toridori.me'
    PORTAL_HOST = 'https://marketing-portal.toridori.me'
    break
  case 'staging':
    API_HOST = 'https://marketing-api.stg.toridori.me'
    FRONT_HOST = 'https://marketing.stg.toridori.me'
    USER_FRONT_HOST = 'https://top-marketing.toridori.me'
    GRAPHQL_API_HOST = 'https://pf-api.stg.toridori.me'
    PORTAL_HOST = 'https://marketing-portal.stg.toridori.me'
    break
  default:
    API_HOST = 'https://marketing-api.xdev.toridori.me'
    USER_FRONT_HOST = 'https://top-marketing.toridori.me'
    FRONT_HOST = 'https://marketing.xdev.toridori.me'
    GRAPHQL_API_HOST = 'https://toridori-marketing-api.xdev.toridori.me'
    PORTAL_HOST = 'https://marketing-portal.toridori.me'
}

if (process.env.NODE_ENV !== 'production') {
  API_HOST = process.env.NEXT_PUBLIC_API_HOST || API_HOST
  FRONT_HOST = process.env.NEXT_PUBLIC_FRONT_HOST || FRONT_HOST
  USER_FRONT_HOST = process.env.NEXT_PUBLIC_USER_FRONT_HOST || USER_FRONT_HOST
  GRAPHQL_API_HOST = process.env.NEXT_PUBLIC_GRAPHQL_API_HOST || GRAPHQL_API_HOST
  PORTAL_HOST = process.env.NEXT_PUBLIC_PORTAL_HOST || PORTAL_HOST
}

export { API_HOST, FRONT_HOST, GRAPHQL_API_HOST, PORTAL_HOST, USER_FRONT_HOST }

/**
 * CONSTANTS
 */

const isProdLike = process.env.NEXT_PUBLIC_APP_ENV === 'production' || process.env.NEXT_PUBLIC_APP_ENV === 'staging'

export const FIREBASE_CONFIG = isProdLike
  ? {
      // for production or staging
      fcmWebPushKey: 'BKSKzZwAJCecdF1ST1-YSASnb1ICxrvpGPLp2fAi-sh8URRbU1kXTWbiAbh_LASCQyU8gKgBuIk4opvL0qBE4Z4',
      apiKey: 'AIzaSyBHf626sYUg5kNMgPPtwUdGG8837bCfin4',
      authDomain: 'collabomarketing-e8d43.firebaseapp.com',
      databaseURL: 'https://collabomarketing-e8d43.firebaseio.com',
      projectId: 'collabomarketing-e8d43',
      storageBucket: 'collabomarketing-e8d43.appspot.com',
      messagingSenderId: '1037612981487',
      appId: '1:1037612981487:web:a10e292a9420dcbb56cf1a',
      measurementId: 'G-BKW1LDMTMM',
    }
  : {
      // for development
      fcmWebPushKey: 'BG8-ZrvNodSoeaBjIiESKCiwub_QmVJL78u9FUT-Hqf0oSMC2al91_EJ8MZm5qf4e7DWJgRccv3ifCj7ySlpddQ',
      apiKey: 'AIzaSyBEzBhALRmUfmn6W7oX0wXqfthNjPG7V_o',
      authDomain: 'collabomarketing-dev.firebaseapp.com',
      databaseURL: 'https://collabomarketing-dev.firebaseio.com',
      projectId: 'collabomarketing-dev',
      storageBucket: 'collabomarketing-dev.appspot.com',
      messagingSenderId: '934949486805',
      appId: '1:934949486805:web:d50e58a7d9981594b1625c',
      measurementId: 'G-GFYS231NZV',
    }

export const APP_ID = isProdLike ? 'jp.co.collabomarketing' : 'jp.co.collabomarketing.dev'

export const APPLE_APP_ID = `UQ4TAGTYU3.${APP_ID}`

export const ZENDESK_KEY = 'cd579bff-60ea-45f9-b859-19503461af2c'

export const PAY_JP_KEY = isProdLike ? 'pk_live_117f4c673779d06fa762bd29' : 'pk_test_e574dfa7cce0adfef1a144a6'

export const CUSTOM_SCHEME = isProdLike ? 'toridorimarketing://' : 'dtoridorimarketing://'

export const TAG_MANAGER_ID = isProdLike ? 'GTM-PLGGDKV' : 'GTM-KGGH8SD'

export const GOOGLE_ANALYTICS_ID = isProdLike ? 'G-BKW1LDMTMM' : 'G-GFYS231NZV'

export const INSTAGRAM_REPOST_ID = isProdLike ? 'QmVnaW5uZXJQb3N0Ojg=' : 'QmVnaW5uZXJQb3N0OjM='

export const ANDROID_CERTIFICATE_SHA256 = isProdLike
  ? [
      'f4:3c:41:13:2a:51:d6:ac:53:57:20:52:fb:a2:be:cc:86:83:a4:e9:85:34:bf:2c:19:89:64:41:a5:0d:bd:b3',
      '20:62:8D:B3:54:BC:B6:09:56:05:D0:14:41:1A:F6:07:D1:B1:00:8E:21:D0:54:0F:DB:AF:36:8D:1E:EC:C4:8A',
    ]
  : ['A8:98:7E:53:1F:4A:0A:E9:FF:59:AD:71:55:3A:41:51:ED:F4:95:CE:FC:43:B9:FF:4C:F6:B3:DA:EE:F0:58:76']

export const BUGSNAG_API_KEY = 'f8a6a891caf68c5aed709314f3f82216'

export const DYNAMIC_LINK = isProdLike
  ? 'https://toridorimarketing.page.link/zNrP'
  : 'https://dtoridorimarketing.page.link/DSF9'

export const IMAGE_HOST = isProdLike ? 'https://image.collatech.net' : 'https://image.xdev.toridori.me'
export const APPLE_REDIRECT_URL = isProdLike ? 'jp.co.collabomarketing' : 'jp.co.collabomarketing.dev'

export const MUI_LICENSE_KEY =
  '42f560ab72050cd2e8025782302ff356Tz03NjAwNyxFPTE3MjgxMDMyNjkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='

export const FACEBOOK_APP_ID = isProdLike ? '623453755804288' : '1085230129048706'

export const CLARITY_ID = isProdLike ? 'bizkqytwo4' : 'du3xksexh5'

export const UGC_CONTENTS_LINK = isProdLike
  ? 'https://ugc-contents.toridori.me'
  : 'https://ugc-contents.xdev.toridori.me'

// これは別ファイルで定義するべきかも
export const LINE_LINK = 'https://line.me/R/ti/p/%40jby6639u'
