/**
 * toridori-marketing-apiのトークンをlocalStorageに保存するためのキー
 */
export const PF_TOKEN_LOCAL_STORAGE_KEY = 'toridori-marketing-api-token'

/**
 * バナーを非表示にするためのキー
 */
export const STORAGE_KEY_HIDE = 'hideAppLinkBanner'

/**
 * 満足度アンケートを表示するかどうか。
 */
export const AVAILABLE_SATISFACTION_SURVEY = 'availableSatisfactionSurveyId'

/**
 * トランザクショナルアンケートを表示するかどうか。
 */
export const AVAILABLE_TRANSACTIONAL_SURVEY = 'availableTransactionalSurvey'
