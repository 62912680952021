import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { WHITE_SPACE_REGEXP } from 'utils/constants'
import { MAX_LENGTH_VALIDATION_MESSAGE, trimStartHash, validate } from '.'

export type MultipleTagInputProps = {
  multiple: true
  value: string[]
  errorMessage?: string
  onChange: (values: string[], errorMessage?: string) => void
  disabled?: boolean
  undeletables?: string[]
  maxLength?: number
}

export const MultipleTagInput: React.VFC<MultipleTagInputProps> = ({
  value,
  errorMessage,
  onChange,
  disabled,
  undeletables,
  maxLength,
}) => {
  // NOTE: Enterキーで確定のみならこのstateは必要ないが、
  //       追加ボタンの押下でタグ追加したい場合は、
  //       AutocompleteのonChangeで渡ってくる現在の値にアクセスする方法が
  //       他にないので必要になる。
  // NOTE: スマートフォンでEnterキーをハンドリングすることができないため、
  //       追加ボタンが必要
  // NOTE: 理想的にはuseAutocompleteを使用して独自コンポーネントを作りたい
  const [editing, setEditing] = useState('')

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <Autocomplete
          multiple
          freeSolo
          value={value.filter((tag) => !!tag)}
          inputValue={editing}
          defaultValue={undeletables}
          options={undeletables || ([] as string[])}
          ListboxProps={{ style: { display: 'none' } }}
          disableClearable
          disabled={disabled || maxLength === value.length}
          onBlur={() => {
            if (!editing) return
            // バリデーション不正のハンドリング
            const validationResult = validate(editing)
            if (validationResult) {
              onChange(value, validationResult)
              setEditing('')
              return
            }

            // 同一タグのバリデーション
            if (value.includes(editing)) {
              // NOTE: Enterキーでの同一タグ追加時挙動(muiのAutocompleteの挙動)と合わせるために、
              // エラーメッセージは渡さない
              onChange(value)
              setEditing('')
              return
            }

            setEditing('')
            onChange([...value, trimStartHash(editing)])
          }}
          onChange={(_, tags, reason, details) => {
            // Enterが押された時
            if (!details?.option) {
              return
            }
            if (reason === 'blur' || reason === 'clear' || reason === 'selectOption') return
            // 削除不可タグのハンドリング
            if (reason === 'removeOption' && undeletables?.includes(details.option)) return

            // 長さのハンドリング
            if (maxLength && maxLength < tags.length) {
              onChange(tags.slice(0, tags.length - 1), MAX_LENGTH_VALIDATION_MESSAGE)
              return
            }

            // バリデーション不正のハンドリング
            const validationResult = validate(details.option)
            if (validationResult) {
              onChange(tags.slice(0, tags.length - 1), validationResult)
              return
            }

            onChange(tags.map((tag) => trimStartHash(tag)))
          }}
          onInputChange={(_, inputValue) => {
            const lastValue = inputValue.slice(-1) // 最後の文字
            if (/^\s/.test(inputValue)) return // 先頭が空白の場合は何もしない(editingも変更しない)
            if (!WHITE_SPACE_REGEXP.test(lastValue)) {
              setEditing(inputValue)
              return
            } else {
              // 最後の文字がスペースの場合はタグを確定

              // 空白を除去
              const trimmedInputValue = inputValue.trim()

              //  // 長さのハンドリング
              //  if (maxLength && maxLength < inputValue.length) {
              //   onChange(value, MAX_LENGTH_VALIDATION_MESSAGE)
              //   setEditing('')
              //   return
              // }

              // バリデーション不正のハンドリング
              const validationResult = validate(editing)
              if (validationResult) {
                onChange(value, validationResult)
                setEditing('')
                return
              }

              // 同一タグのバリデーション
              if (value.includes(trimmedInputValue)) {
                // NOTE: Enterキーでの同一タグ追加時挙動(muiのAutocompleteの挙動)と合わせるために、
                // エラーメッセージは渡さない
                onChange(value)
                setEditing('')
                return
              }

              setEditing('')
              onChange([...value.filter((tag) => !!tag), trimStartHash(trimmedInputValue)])
              return
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                // NOTE: editingをTextfieldのvalueに反映させても、
                //       適切に再レンダリングされない場合があるため、
                //       inputのvalueに反映させている
                value: editing,
              }}
              variant="outlined"
              placeholder={`Enterかスペースで確定(${maxLength}個まで)`}
              error={!!errorMessage}
            />
          )}
          renderTags={(tags, getTagProps) => (
            <>
              {tags.map((tag, i) => (
                <Chip
                  {...getTagProps({ index: i })}
                  key={i}
                  label={tag}
                  color="primary"
                  disabled={undeletables?.includes(tag)}
                />
              ))}
            </>
          )}
          style={{
            flexGrow: 1,
          }}
        />
      </Box>
      <Box height="30px">
        {errorMessage && (
          <Typography variant="caption" color="error">
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
