import { MultipleTagInput, MultipleTagInputProps } from './MultipleTagInput'
import { SingleTagInput, SingleTagInputProps } from './SingleTagInput'

type Props = MultipleTagInputProps | SingleTagInputProps

export const TagInput: React.FC<Props> = (props) => {
  if (props.multiple) return <MultipleTagInput {...props} />
  return <SingleTagInput {...props} />
}

export const trimStartHash = (value: string) => (value.startsWith('#') ? value.substring(1) : value)

export const trimText = (text: string) =>
  String.raw({ raw: text })
    .split('\n')
    .map((s) => s.trim())
    .join('\n')
    .replace(/(^\n)|(\n$)/g, '')

export const MAX_LENGTH_VALIDATION_MESSAGE = '指定可能なタグの数の上限に達しました。'

const SPECIAL_CHAR = /.*[!"$%&'()*+,./:;<=>?@^`{|}~#＠％＆＃].*/
const BLANK = / |\u3000/
export const validate = (tag: string) => {
  if (tag.startsWith('#') && !SPECIAL_CHAR.test(tag.substring(1))) return null // #で始まるタグは許容
  if (SPECIAL_CHAR.test(tag)) {
    return 'ハッシュタグに特殊文字を含めることはできません。'
  }
  if (BLANK.test(tag)) return 'ハッシュタグにスペースを含めることはできません。'
  return null
}
