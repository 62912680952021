import { CustomErrorPage } from 'components/common/CustomErrorPage'
import { NextPageContext } from 'next'
import ErrorPage from 'next/error'
import Bugsnag from 'utils/bugsnag'
import { IS_PRODUCTION } from 'utils/config'

const errorTitle = 'エラーが発生しました'

const errorDetail = [
  '大変申し訳ございません。想定していないエラーが発生しました。',
  'お手数をおかけしますが、toridori marketingのホームに戻っていただきますようお願いいたします。',
]

const Error = ({ error, info }: { error: Error; info: React.ErrorInfo }) => {
  return (
    <CustomErrorPage
      errorTitle={errorTitle}
      errorDetail={errorDetail}
      forDev={!IS_PRODUCTION ? { error, info } : undefined}
    />
  )
}

Error.getInitialProps = (ctx: NextPageContext) => {
  if (ctx.err) {
    Bugsnag.setContext(ctx.err.message)
    Bugsnag.notify(ctx.err)
  }
  return ErrorPage.getInitialProps(ctx)
}

export default Error
