import { useEffect, useState } from 'react'
import { drawerWidth, theme } from 'utils/theme'
import { AppFooter } from './AppFooter'
import { useNativeAppBanner } from 'components/common/NativeAppBanner'
import { Enquate } from 'components/common/Enquate'
import { AppHeader } from './AppHeader'
import { AppDrawer } from './AppDrawer'
import { Box, Button, Stack, Toolbar, useMediaQuery } from '@mui/material'
import { ChatSupportButton } from './ChatSupportButton'
import { useRouter } from 'next/router'
import { pagesPath } from 'utils/$path'
import { useSetCustomProperties } from 'hooks/app/useSetCustomProperties'

export const AppLayout = (props: { children: React.ReactNode }) => {
  const router = useRouter()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [drawerOpen, setDrawerOpen] = useState(true)

  // techtouchカスタムプロパティのセット
  useSetCustomProperties()

  useEffect(() => {
    // 画面がmobileサイズなったときにサイドバーを自動で閉じる
    if (isMobile) {
      setDrawerOpen(false)
    }
  }, [isMobile])

  const { banner, bannerOpen: show, bannerHeight } = useNativeAppBanner()

  return (
    <>
      {banner}
      <Box
        sx={{
          position: 'fixed',
          bottom: 30,
          right: 20,
          zIndex: 101,
        }}
        className="mui-fixed"
      >
        <ChatSupportButton />
      </Box>
      <Stack
        sx={{
          mt: show ? bannerHeight : 0,
        }}
      >
        <AppDrawer open={drawerOpen} isMobile={isMobile} onClose={() => setDrawerOpen(false)} />
        <Box
          sx={(theme) => ({
            ml: drawerOpen && !isMobile ? drawerWidth + 'px' : 0,
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.leavingScreen,
            }),
          })}
        >
          <AppHeader
            drawerOpen={drawerOpen}
            onDrawerOpen={() => setDrawerOpen(true)}
            bannerHeight={bannerHeight}
            bannerOpen={show}
          />
          <Enquate
            sx={{
              ml: drawerOpen && !isMobile ? drawerWidth + 'px' : 0,
              transition: theme.transitions.create('margin', {
                duration: theme.transitions.duration.leavingScreen,
              }),
            }}
          />
          <Box
            sx={{
              minHeight: '100vh',
            }}
            component="main"
          >
            {/* AppBarの高さ分 */}
            <Toolbar />
            {props.children}
          </Box>

          {/* NOTE: iOS WebviewでFooterが崩れる */}
          {/* 暫定: isMobile時に表示しない */}
          {!isMobile && <AppFooter />}

          {/* ボタン押下時の挙動はテックタッチで作成するため、コード上では挙動を記載しない */}
          {router.pathname === pagesPath.cancellations.subscription.$url().pathname && (
            <Button variant="contained" sx={cancelBtnStyle}>
              ご解約手続きはこちら
            </Button>
          )}

          {/* 特にフッターが表示されない場合においてサポートボタンがコンテンツに重なりうるためスペースを確保する */}
          {isMobile && (
            <Box sx={{ visibility: 'hidden', paddingBottom: '30px' }}>
              <ChatSupportButton />
            </Box>
          )}
        </Box>
      </Stack>
    </>
  )
}

const cancelBtnStyle = {
  position: 'fixed',
  bottom: '90px',
  right: '20px',
  borderRadius: '50px',
  padding: '13.5px 20px',
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)',
  fontWeight: 'bold',
}
