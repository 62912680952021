import { Fab } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import NextLink from 'next/link'
import { pagesPath } from 'utils/$path'

export const CreateCampaignButton = () => (
  <NextLink href={pagesPath.campaign_create.$url()} passHref legacyBehavior>
    <Fab
      variant="extended"
      sx={{
        m: 2,
        mt: 0,
        gap: 1,
        fontWeight: 'bold',
        // NOTE:解像度の低い画面で改行されてしまう問題の対策
        whiteSpace: 'nowrap',
        flexShrink: 0,
        // color palletから参照しなくてよい
        color: (theme) => theme.palette.common.white,
        bgcolor: 'rgb(0, 87, 183)',
        ':hover': {
          bgcolor: 'rgb(0, 60, 128)',
        },
      }}
      // color='primary'
      aria-label="add"
    >
      <AddIcon />
      新規キャンペーン作成
    </Fab>
  </NextLink>
)
