import { ApolloError } from '@apollo/client'
import { Alert, Typography } from '@mui/material'
import { isMarketingApiError, MarketingApiError } from 'types/error/MarketingApiError'

type Props = {
  title: string
  error: MarketingApiError | ApolloError
}

export const ErrorAlert: React.FC<Props> = ({ title, error }) => {
  return isMarketingApiError(error) ? (
    <MarketingErrorAlert title={title} error={error} />
  ) : (
    <ApolloErrorAlert title={title} error={error} />
  )
}

const MarketingErrorAlert = ({ title, error }: { title: string; error: MarketingApiError }) => (
  <Alert severity="error">
    <Typography>{title}</Typography>
    <ul>
      {error.response.data.error.messages.map((message, i) => (
        <li key={i}>
          <Typography>{message}</Typography>
        </li>
      ))}
    </ul>
    <Typography>エラーコード： {error.response.data.error.code}</Typography>
  </Alert>
)

const ApolloErrorAlert = ({ title, error }: { title: string; error: ApolloError }) => (
  <Alert severity="error">
    <Typography>{title}</Typography>
    <Typography>{error.message}</Typography>
  </Alert>
)
