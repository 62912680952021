import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Box, IconButton, Link, Toolbar, useMediaQuery } from '@mui/material'
import NextLink from 'next/link'
import { pagesPath, staticPath } from 'utils/$path'
import { UserInfo } from './UserInfo'
import { UserMenu } from './UserMenu'
import { theme } from 'utils/theme'

type Props = {
  drawerOpen: boolean
  onDrawerOpen?: () => void
  bannerHeight?: string
  bannerOpen?: boolean
}

export const AppHeader = ({ drawerOpen, onDrawerOpen, bannerHeight, bannerOpen = false }: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <AppBar
      sx={{
        backgroundColor: (theme) => theme.palette.toridori,
        position: 'fixed',
        boxShadow: 'none',
        borderBottom: '1px solid',
        borderColor: 'divider',
        top: bannerOpen ? bannerHeight : 0,
      }}
    >
      <Toolbar>
        {!drawerOpen && (
          <>
            <IconButton color="inherit" onClick={onDrawerOpen} edge="start" size="large" sx={{ color: 'white' }}>
              <MenuIcon />
            </IconButton>
            <NextLink href={pagesPath.home.$url()} passHref legacyBehavior>
              <Link
                sx={{
                  lineHeight: 1,
                  height: '24px',
                  ml: 1,
                }}
              >
                <img
                  src={staticPath.toridori_marketing_1_dark_png}
                  alt="トリドリマーケティング"
                  style={{ height: '100%', objectFit: 'contain' }}
                />
              </Link>
            </NextLink>
          </>
        )}

        <Box sx={{ flexGrow: 1 }}></Box>

        {/* お客様情報 */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!isMobile && <UserInfo />}
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  )
}
