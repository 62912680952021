import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const TrainIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 1000 1000">
    <g transform="matrix(1 0 0 -1 0 850)">
      <path
        d="M444 711q-24 0 -44 -16q-18 -15 -28 -39h-39q-37 0 -69 -19t-51 -51t-19 -69v-306q0 -49 27.5 -90t71.5 -62l-71 -70h65l57 56l17 -1h278l17 1l57 -56h65l-70 70q44 21 71 62t27 90v306q0 37 -19 69t-51 51t-69 19h-39q-11 24 -28 39q-19 16 -44 16h-112zM444 656h112
q11 0 19 -8t8 -20v-28h84q27 0 48.5 -15.5t30.5 -40.5h-492q9 25 30.5 40.5t48.5 15.5h84v28q0 12 8 20t19 8zM250 489h500v-139h-500v139zM250 294h500v-83q0 -30 -15 -55.5t-40.5 -40.5t-55.5 -15h-278q-30 0 -55.5 15t-40.5 40.5t-15 55.5v83zM500 267q-23 0 -39.5 -16.5
t-16.5 -39.5t16.5 -39t39.5 -16t39.5 16t16.5 39t-16.5 39.5t-39.5 16.5z"
      />
    </g>
  </SvgIcon>
)

export const MoneyIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 1000 1000">
    <g transform="matrix(1 0 0 -1 0 850)">
      <path
        d="M500 663q-85 0 -157 -43q-71 -42 -112 -112q-43 -73 -43 -158t43 -157q41 -71 112 -112q72 -43 157 -43t158 43q70 41 112 112q43 72 43 157t-43 158q-42 70 -112 112q-73 43 -158 43zM500 725q102 0 190 -51q84 -50 134 -134q51 -88 51 -190t-51 -190q-50 -84 -134 -134
q-88 -51 -190 -51t-190 51q-84 50 -134 134q-51 88 -51 190t51 190q50 84 134 134q88 51 190 51zM439 553q-9 9 -22 9t-22.5 -9t-9.5 -22t9 -22l75 -75v-56h-73q-9 0 -15 -6t-6 -15t6 -15t15 -6h73v-28h-73q-9 0 -15 -6t-6 -14.5t6 -14.5t15 -6h73v-101q0 -13 9 -22t22 -9
t22 9t9 22v101h79q8 0 14.5 6t6.5 14.5t-6.5 14.5t-14.5 6h-79v28h79q8 0 14.5 6t6.5 15t-6.5 15t-14.5 6h-79v51l80 80l2 1q8 10 7.5 22t-9.5 21t-22 9t-22 -9l-61 -62l-2 -1l-1 -2l-3 3z"
      />
    </g>
  </SvgIcon>
)

export const AuthenticatedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 116 116">
    <circle cx="58" cy="58" r="52" fill="#44D52C" stroke="#0057B7" strokeWidth="12" />
    <path d="M23 63.3308L47.037 81L89 34" stroke="white" strokeWidth="18" fill="none" />
  </SvgIcon>
)

export const BeginnerIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 512 512">
    <path
      d="M256,120.07L145.016,12.742C131.953,0.102,112.594-3.492,95.844,3.586
		c-16.734,7.109-27.609,23.531-27.609,41.719v274c0,18.406,7.469,36.031,20.703,48.844L224.5,499.258
		c17.563,16.984,45.438,16.984,62.984,0l135.578-131.109c13.234-12.813,20.703-30.438,20.703-48.844v-274
		c0-18.188-10.875-34.609-27.609-41.719c-16.75-7.078-36.109-3.484-49.172,9.156L256,120.07z M379.844,311.414
		c0,6.141-2.484,12.016-6.906,16.281L256,440.805V209.008l22.219-21.5l82.438-79.719c3.25-3.156,8.109-4.063,12.281-2.281
		c4.188,1.766,6.906,5.875,6.906,10.422V311.414z"
    />
  </SvgIcon>
)
