import { DateTime } from 'luxon'
import Router from 'next/router'
import { useEffect, useRef } from 'react'

const ONE_DAY_MILLI_SEC = 86400000

export const useReloadAfterOneDay = () => {
  const firstRendered = useRef(DateTime.local().toMillis())

  useEffect(() => {
    const handleRouteChanged = () => {
      // パスが切り替わった後で、日時チェック
      // Appのレンダリングから1日以上経過していたら、リロードさせる
      const now = DateTime.local().toMillis()
      if (now - firstRendered.current > ONE_DAY_MILLI_SEC) {
        Router.reload()
      }
    }
    Router.events.on('routeChangeComplete', handleRouteChanged)
    Router.events.on('routeChangeError', handleRouteChanged)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChanged)
      Router.events.off('routeChangeError', handleRouteChanged)
    }
  }, [])
}
