import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { useEffect } from 'react'
import { IS_PRODUCTION, IS_STAGING } from 'utils/config'

const LOG_ROCEKT_ID = 'jvzupd/collabomarketing'
const GOOGLE_HOST_NAMES = ['www.googleapis.com', 'identitytoolkit.googleapis.com'] as const

export const useLogRocketInit = () => {
  useEffect(() => {
    if (IS_PRODUCTION || IS_STAGING) {
      LogRocket.init(LOG_ROCEKT_ID, {
        network: {
          requestSanitizer: (request) => {
            if (!request.url || !(request.url.startsWith('http://') || request.url.startsWith('https://'))) {
              return request
            }
            const url = new URL(request.url)
            // firebase へのリクエスト情報は保存しない
            if (GOOGLE_HOST_NAMES.includes(url.hostname as (typeof GOOGLE_HOST_NAMES)[number])) {
              return null
            }
            return request
          },
        },
      })
      setupLogRocketReact(LogRocket)
    }
  }, [])
}
