import { AdvAccount, SummaryReports } from 'types/AdvAccount'
import { ApiRequest } from '../client'

export const getAdvAccount = async () => {
  const { advAccount } = await ApiRequest<{ advAccount: AdvAccount }>({
    url: '/v1/adv_accounts/me',
  })
  return advAccount
}

export type CreateAdvAccountProps = {
  companyName: string
  companyNameFurigana: string
  companyUrl: string
  representativeName: string
  postalcode?: string
  pref?: number
  city?: string
  address1?: string
  address2?: string
}

export const createAdvAccount = async (data: CreateAdvAccountProps) => {
  const { advAccount } = await ApiRequest<{ advAccount: AdvAccount }>({
    method: 'POST',
    url: '/v1/adv_accounts',
    data: data,
  })
  return advAccount
}

export const updateAdvAccount = async (
  advId: number,
  data: Partial<
    Pick<
      AdvAccount,
      | 'companyName'
      | 'companyNameFurigana'
      | 'representativeName'
      | 'companyUrl'
      | 'postalcode'
      | 'pref'
      | 'city'
      | 'address1'
      | 'address2'
    >
  >,
) => {
  // on server-side, user adv info is fetch from firebase
  const { advAccount } = await ApiRequest<{ advAccount: AdvAccount }>({
    method: 'PATCH',
    url: `/v1/adv_accounts/${advId}`,
    data: {
      adv_account: data,
    },
  })
  return advAccount
}

export const getCampaignReport = async (from: string, to: string, campaignId: number) => {
  const reports = await ApiRequest<SummaryReports>({
    url: `/v1/adv_accounts/report/${campaignId}`,
    params: {
      from: from,
      to: to,
    },
  })
  return reports
}
