/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ApolloClient, ApolloLink, ApolloProvider, from, InMemoryCache, TypePolicies } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

/**
 * @see https://github.com/jaydenseric/apollo-upload-client/issues/336
 */
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { PF_TOKEN_LOCAL_STORAGE_KEY } from 'constants/local_storage_key'
import { FC, ReactNode } from 'react'
import { getPfToken } from 'repository/api/PfApiRepository'
import dayjs from 'dayjs'
import { proxyLogin } from 'utils/proxyLogin'
import { relayStylePagination } from '@apollo/client/utilities'
import { ViewerDocument } from 'generated/graphql'
import { GRAPHQL_API_HOST } from 'utils/config'

const uri = GRAPHQL_API_HOST + '/graphql'

const operationNameLink = new ApolloLink((operation, forward) => {
  const { operationName } = operation
  operation.setContext({ uri: `${uri}?operationName=${operationName}` })
  return forward(operation)
})

const uploadLink = createUploadLink({ uri })

/**
 * トークンの有効期限が切れているかのフラグ
 * onErrorに非同期コールバックを渡せないため、やむを得ずこうしてる
 */
let isExpired = false
const authLink = setContext(async (_, { headers }) => {
  const pfToken = await getPfToken({ refresh: isExpired })
  isExpired = false
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${pfToken}`,
    },
  }
})

const errorLink = onError(({ graphQLErrors, operation, response, forward }) => {
  if (!graphQLErrors) return
  const newError = graphQLErrors?.map((args) => {
    const { message, ...rest } = args
    const { extensions } = args
    if (!extensions) return args
    if (!('code' in extensions)) return args
    const { code } = extensions
    switch (code) {
      case 'UNAUTHENTICATED':
        // トークン期限切れエラーの場合は期限切れフラグを有効にし、リトライする
        // onErrorに非同期コールバックを渡せればここでgetPfTokenをawaitすればよいが、出来ないためフラグを有効にし、
        // authLinkに期限切れであることを伝える
        isExpired = message.includes('TokenExpiredError')
        return args
      case 'FORBIDDEN':
        return {
          message: 'アクセス権限がありません',
          ...rest,
        }

      case 'UNKNOWN_ERROR':
        return {
          message: '不明なエラーです',
          ...rest,
        }
      case 'INTERNAL_SERVER_ERROR':
        return {
          message: 'サーバーエラーが発生しました',
          ...rest,
        }
      case 'GRAPHQL_VALIDATION_FAILED':
      case 'BAD_USER_INPUT':
        return args
      default:
        return args
    }
  })
  if (newError && response?.errors) {
    response.errors = newError
  }
  return isExpired ? forward(operation) : undefined
})

// TODO: fetchMore内でのupdateQueryがdeprecatedでApollo Client v4.0で削除されるらしいので、フィールドポリシー内でキャッシュを操作する @mayone-du
const typePolicies: TypePolicies = {
  Company: {
    fields: {
      issuedTickets: relayStylePagination(),
    },
  },

  Campaign: {
    fields: {
      offers: relayStylePagination(),
    },
  },
  PrInsight: {
    fields: {
      aggregationTargetDate: {
        read: (val: string) => {
          return dayjs(val).format('MM/DD')
        },
      },
    },
  },
  Advertiser: {
    fields: {
      instagramPosts: relayStylePagination(),
    },
  },
}

const client = new ApolloClient({
  uri,
  link: from([errorLink, authLink, operationNameLink, uploadLink]),
  cache: new InMemoryCache({
    typePolicies,
  }),
})

export const removePfToken = () => {
  localStorage.removeItem(PF_TOKEN_LOCAL_STORAGE_KEY)
  client.clearStore()
  proxyLogin.clearProxyAdvAccountId()
}

export const AppApolloProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export const prefetchViewer = () => {
  client.query({ query: ViewerDocument }).catch((e) => {
    console.error(new Error('Failed to prefetch viwer', { cause: e }))
  })
}
