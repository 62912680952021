import type { OptionalQuery as OptionalQuery_rmgz7n } from '../pages/beginner/[postId]';
import type { OptionalQuery as OptionalQuery_re55mu } from '../pages/beginner/instagram';
import type { OptionalQuery as OptionalQuery_1i4l0ny } from '../pages/campaign_create';
import type { OptionalQuery as OptionalQuery_ibulz5 } from '../pages/reports/[instagramReportType]';
import type { Query as Query_emtski } from '../pages/reports/campaigns/[campaignId]';

const buildSuffix = (url?: {query?: Record<string, string>, hash?: string}) => {
  const query = url?.query;
  const hash = url?.hash;
  if (!query && !hash) return '';
  const search = query ? `?${new URLSearchParams(query)}` : '';
  return `${search}${hash ? `#${hash}` : ''}`;
};

export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "adv_account": {
    "credit": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/credit' as const, hash: url?.hash })
    },
    "credit_register": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/credit_register' as const, hash: url?.hash })
    },
    "credit_registration": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/credit_registration' as const, hash: url?.hash })
    },
    "mfkessai_customer_info": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/mfkessai_customer_info' as const, hash: url?.hash })
    },
    "mfkessai_registration": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/mfkessai_registration' as const, hash: url?.hash })
    },
    "personal_info": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/personal_info' as const, hash: url?.hash })
    },
    "register_payment": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/adv_account/register_payment' as const, hash: url?.hash })
    }
  },
  "auth_transfer": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/auth_transfer' as const, hash: url?.hash })
  },
  "beginner": {
    _postId: (postId: string | number) => ({
      $url: (url?: { query?: OptionalQuery_rmgz7n | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/beginner/[postId]' as const, query: { postId, ...url?.query }, hash: url?.hash })
    }),
    "instagram": {
      $url: (url?: { query?: OptionalQuery_re55mu | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/beginner/instagram' as const, query: url?.query, hash: url?.hash })
    }
  },
  "campaign_create": {
    $url: (url?: { query?: OptionalQuery_1i4l0ny | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/campaign_create' as const, query: url?.query, hash: url?.hash })
  },
  "campaign_entries": {
    _campaignEntryId: (campaignEntryId: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/campaign_entries/[campaignEntryId]' as const, query: { campaignEntryId }, hash: url?.hash })
    })
  },
  "campaigns": {
    _campaignId: (campaignId: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/campaigns/[campaignId]' as const, query: { campaignId }, hash: url?.hash }),
      "campaign_entries": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/campaigns/[campaignId]/campaign_entries' as const, query: { campaignId }, hash: url?.hash })
      },
      "edit": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/campaigns/[campaignId]/edit' as const, query: { campaignId }, hash: url?.hash })
      },
      "offers": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/campaigns/[campaignId]/offers' as const, query: { campaignId }, hash: url?.hash })
      },
      "reservation": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/campaigns/[campaignId]/reservation' as const, query: { campaignId }, hash: url?.hash })
      },
      "schedule": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/campaigns/[campaignId]/schedule' as const, query: { campaignId }, hash: url?.hash })
      }
    }),
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/campaigns' as const, hash: url?.hash })
  },
  "cancellations": {
    "subscription": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/cancellations/subscription' as const, hash: url?.hash })
    }
  },
  "contract": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/contract' as const, hash: url?.hash })
  },
  "creditcards": {
    "payment_histories": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/creditcards/payment_histories' as const, hash: url?.hash })
    }
  },
  "home": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/home' as const, hash: url?.hash })
  },
  "instagram_website": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/instagram_website' as const, hash: url?.hash })
  },
  "invoice": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/invoice' as const, hash: url?.hash })
  },
  "offer_reports": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/offer_reports' as const, hash: url?.hash })
  },
  "offers": {
    _offerId: (offerId: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/offers/[offerId]' as const, query: { offerId }, hash: url?.hash })
    })
  },
  "register_complete": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/register_complete' as const, hash: url?.hash })
  },
  "reports": {
    _instagramReportType: (instagramReportType: string | number) => ({
      $url: (url?: { query?: OptionalQuery_ibulz5 | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/reports/[instagramReportType]' as const, query: { instagramReportType, ...url?.query }, hash: url?.hash })
    }),
    "campaigns": {
      _campaignId: (campaignId: string | number) => ({
        $url: (url: { query: Query_emtski, hash?: string | undefined }) => ({ pathname: '/reports/campaigns/[campaignId]' as const, query: { campaignId, ...url.query }, hash: url.hash })
      })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/reports' as const, hash: url?.hash })
  },
  "reset_password": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/reset_password' as const, hash: url?.hash })
  },
  "signin": {
    "apple_signin": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signin/apple_signin' as const, hash: url?.hash })
    },
    "support": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signin/support' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signin' as const, hash: url?.hash })
  },
  "signup": {
    "account": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signup/account' as const, hash: url?.hash })
    },
    "password": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signup/password' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/signup' as const, hash: url?.hash })
  },
  "subscriptions": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/subscriptions' as const, hash: url?.hash })
  },
  "todo_offers": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/todo_offers' as const, hash: url?.hash })
  },
  "ugc_tags": {
    "items": {
      _itemId: (itemId: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/ugc_tags/items/[itemId]' as const, query: { itemId }, hash: url?.hash })
      }),
      "masterItem": {
        _itemId: (itemId: string | number) => ({
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/ugc_tags/items/masterItem/[itemId]' as const, query: { itemId }, hash: url?.hash })
        })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/ugc_tags/items' as const, hash: url?.hash })
    },
    "posts": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/ugc_tags/posts' as const, hash: url?.hash })
    },
    "preview": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/ugc_tags/preview' as const, hash: url?.hash })
    },
    "report": {
      _report: (report: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/ugc_tags/report/[report]' as const, query: { report }, hash: url?.hash })
      })
    },
    "settings": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/ugc_tags/settings' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/ugc_tags' as const, hash: url?.hash })
  },
  "webview_redirect": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/webview_redirect' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  animation: {
    congrats_lottie_json: '/animation/congrats.lottie.json'
  },
  appicon_png: '/appicon.png',
  apple_store_badge_svg: '/apple-store-badge.svg',
  background_png: '/background.png',
  background_mobile_png: '/background_mobile.png',
  blurred_wordcloud_mock_png: '/blurred_wordcloud_mock.png',
  campaign_placeholder: {
    placeholder_1_jpg: '/campaign_placeholder/placeholder_1.jpg',
    placeholder_2_jpg: '/campaign_placeholder/placeholder_2.jpg',
    placeholder_3_jpg: '/campaign_placeholder/placeholder_3.jpg',
    placeholder_4_jpg: '/campaign_placeholder/placeholder_4.jpg',
    placeholder_5_jpg: '/campaign_placeholder/placeholder_5.jpg',
    placeholder_6_jpg: '/campaign_placeholder/placeholder_6.jpg'
  },
  caution_line_subscribe_png: '/caution_line_subscribe.png',
  contract: {
    contract_local_jpg: '/contract/contract_local.jpg',
    contract_remote_jpg: '/contract/contract_remote.jpg'
  },
  create_campaign_openlogi_jpg: '/create_campaign_openlogi.jpg',
  create_campaign_service_plan_png: '/create_campaign_service_plan.png',
  facebook_connect_steps: {
    facebook_auth: {
      step_0_webp: '/facebook_connect_steps/facebook_auth/step-0.webp',
      step_1_webp: '/facebook_connect_steps/facebook_auth/step-1.webp',
      step_2_webp: '/facebook_connect_steps/facebook_auth/step-2.webp',
      step_3_webp: '/facebook_connect_steps/facebook_auth/step-3.webp'
    },
    facebook_page: {
      step_0_webp: '/facebook_connect_steps/facebook_page/step-0.webp',
      step_1_webp: '/facebook_connect_steps/facebook_page/step-1.webp',
      step_2_webp: '/facebook_connect_steps/facebook_page/step-2.webp',
      step_3_webp: '/facebook_connect_steps/facebook_page/step-3.webp',
      step_4_webp: '/facebook_connect_steps/facebook_page/step-4.webp',
      step_5_webp: '/facebook_connect_steps/facebook_page/step-5.webp',
      step_6_webp: '/facebook_connect_steps/facebook_page/step-6.webp',
      step_7_webp: '/facebook_connect_steps/facebook_page/step-7.webp',
      step_8_webp: '/facebook_connect_steps/facebook_page/step-8.webp',
      step_9_webp: '/facebook_connect_steps/facebook_page/step-9.webp'
    },
    instagram_pro_account: {
      intro_webp: '/facebook_connect_steps/instagram_pro_account/intro.webp',
      step_0_webp: '/facebook_connect_steps/instagram_pro_account/step-0.webp',
      step_1_webp: '/facebook_connect_steps/instagram_pro_account/step-1.webp',
      step_2_webp: '/facebook_connect_steps/instagram_pro_account/step-2.webp',
      step_3_webp: '/facebook_connect_steps/instagram_pro_account/step-3.webp',
      step_4_webp: '/facebook_connect_steps/instagram_pro_account/step-4.webp',
      step_5_webp: '/facebook_connect_steps/instagram_pro_account/step-5.webp',
      step_6_webp: '/facebook_connect_steps/instagram_pro_account/step-6.webp',
      step_7_webp: '/facebook_connect_steps/instagram_pro_account/step-7.webp'
    },
    intro_png: '/facebook_connect_steps/intro.png',
    reconnect_webp: '/facebook_connect_steps/reconnect.webp'
  },
  favicon_ico: '/favicon.ico',
  firebase_messaging_sw_js: '/firebase-messaging-sw.js',
  google_play_badge_svg: '/google-play-badge.svg',
  influencer_placeholder: {
    man_jpg: '/influencer_placeholder/man.jpg',
    woman_jpg: '/influencer_placeholder/woman.jpg'
  },
  info_for_new_user_1_png: '/info_for_new_user_1.png',
  info_for_new_user_2_png: '/info_for_new_user_2.png',
  instagram_hp_banner_png: '/instagram_hp_banner.png',
  instagram_hp_setting_1_png: '/instagram_hp_setting_1.png',
  instagram_hp_setting_2_png: '/instagram_hp_setting_2.png',
  instagram_hp_setting_3_png: '/instagram_hp_setting_3.png',
  instagram_website_merit_png: '/instagram_website_merit.png',
  line_png: '/line.png',
  logo_icon_png: '/logo_icon.png',
  mockServiceWorker_js: '/mockServiceWorker.js',
  no_image_png: '/no_image.png',
  preview_ticket_jpg: '/preview_ticket.jpg',
  rank: {
    bronze_png: '/rank/bronze.png',
    gold_png: '/rank/gold.png',
    silver_png: '/rank/silver.png'
  },
  repost_icon_png: '/repost_icon.png',
  signin_logo_png: '/signin_logo.png',
  signup_png: '/signup.png',
  signup_mobile_png: '/signup_mobile.png',
  static: {
    error_shutter_background_png: '/static/error_shutter_background.png',
    line_add_friend_link_ja_png: '/static/line_add_friend_link_ja.png',
    line_button_base_png: '/static/line_button_base.png',
    qr_png: '/static/qr.png'
  },
  subscription_cancel_rule_jpg: '/subscription_cancel_rule.jpg',
  toranomaki_banner_png: '/toranomaki_banner.png',
  toridori_marketing_1_dark_png: '/toridori_marketing_1_dark.png',
  toridori_marketing_logo_1_png: '/toridori_marketing_logo_1.png',
  tutorial: {
    attracting_customers_png: '/tutorial/attracting_customers.png',
    buy_ticket_png: '/tutorial/buy_ticket.png',
    check_applicant_png: '/tutorial/check_applicant.png',
    create_campaign_png: '/tutorial/create_campaign.png',
    effect_analysis_png: '/tutorial/effect_analysis.png',
    lead_wire_png: '/tutorial/lead_wire.png'
  },
  wordcloud_sample_png: '/wordcloud_sample.png'
} as const;

export type StaticPath = typeof staticPath;
