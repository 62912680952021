import LogRocket from 'logrocket'
import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { getAdvAccount } from 'repository/api/AdvAccountRepository'
import { getCurrentUserAdv } from 'repository/api/UserAdvRepository'
import { AdvAccount } from 'types/AdvAccount'
import { UserAdv } from 'types/UserAdv'
import Bugsnag from 'utils/bugsnag'
import { setUserId } from 'utils/gtmHelper'
import { snackActions } from 'utils/snackbarHelper'
import { auth, FirebaseUser } from 'utils/firebase/auth'
import { prefetchViewer } from 'components/provider/AppApolloProvider'
import { InflatingAppLoader } from 'components/common/InflatingAppLoader'
import { isMarketingApiError } from 'types/error/MarketingApiError'
import { pagesPath } from 'utils/$path'
import { setClarityCustomTags } from 'utils/clarirtyHelper'
import { proxyLogin } from 'utils/proxyLogin'
import { IS_PRODUCTION } from 'utils/config'

type IAuth = {
  // 初期値の場合(FirebaseUserが存在するかわからない時)はundefined, FirebaseUserの取得を試み、失敗した(存在しない)場合はnull
  currentUser: FirebaseUser | null | undefined
  currentUserAdv: UserAdv | null | undefined
  setCurrentUserAdv: (user: UserAdv) => void
  currentAdv: AdvAccount | null | undefined
  setCurrentAdv: (account: AdvAccount | null) => void
  /**
   * AdvAccountが参照している外部システムの状態が更新されて、状態の整合性を保ちたい場合などに
   * AdvAccountを再取得する（例：クレジットカードの登録）
   */
  refetchCurrentAdv: () => Promise<{ id: number } | undefined>
}

const AuthContext = createContext<IAuth>(undefined as never)

export const AuthContextProvider = AuthContext.Provider

export const useAuth = () => {
  const { currentUser, currentAdv, currentUserAdv, ...rest } = useContext(AuthContext)

  if (currentUser && currentUserAdv && currentAdv) {
    return { currentUser, currentAdv, currentUserAdv, ...rest }
  }

  throw new Error('Invalid useAuth call')
}

export const usePartialAuth = () => {
  const { currentUser, currentAdv, currentUserAdv, ...rest } = useContext(AuthContext)

  if (currentUser !== undefined && currentUserAdv !== undefined && currentAdv !== undefined) {
    return { currentUser, currentAdv, currentUserAdv, ...rest }
  }

  throw new Error('Invalid usePartialAuth call')
}

export const useAuthController = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { currentUser, currentAdv, currentUserAdv, ...rest } = useContext(AuthContext)

  return rest
}

export const excludePathes = [
  // signup, signinのパス配下ではチェックしない．このケースのみ認証状態に基づくredirect処理は各ページで行う
  pagesPath.reset_password.$url().pathname,
  pagesPath.signin.$url().pathname,
  pagesPath.signup.$url().pathname,
  // 認証用ページなのでリダイレクトしない
  pagesPath.webview_redirect.$url().pathname,
  pagesPath.auth_transfer.$url().pathname,
]

export const AuthProvider = (props: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<FirebaseUser | null>()
  const [currentUserAdv, setCurrentUserAdv] = useState<UserAdv | null>()
  const [currentAdv, setCurrentAdv] = useState<AdvAccount | null>()
  const router = useRouter()

  // FIXME: ページ遷移（URLのパス変更）のたびにwindow.dataLayerに新しいconfigが追加され、
  //        setUserIdした値が書き換えられてします
  //        暫定対応：router.pathnameが変化するたびにsetしなおす
  //        そもそも何経由でこういう挙動になっているのかの調査は必要そう
  useEffect(() => {
    if (currentUserAdv?.id) {
      setUserId(currentUserAdv.id)
    }
  }, [router.pathname])

  const refetchCurrentAdv = async () => {
    try {
      const advAccount = await getAdvAccount()
      setCurrentAdv(advAccount)
      return { id: advAccount.id }
    } catch (_) {
      snackActions.error('アカウント情報の更新に失敗しました')
    }
  }

  const isAuthPage = useMemo(() => excludePathes.some((path) => router.pathname.startsWith(path)), [router.pathname])

  const redirectToSignIn = () => {
    if (isAuthPage) return // 認証が不要なページであればリダイレクトしない
    router.pathname === '/' ? router.push('https://top-marketing.toridori.me') : router.push(pagesPath.signin.$url())
  }

  const handleAuthenticationError = (e: unknown) => {
    console.log(e)
    // TODO: エラーが記録されてなさそう
    if (!isMarketingApiError(e)) {
      snackActions.warning('エラーが発生しました')
      router.push(pagesPath.signin.$url())
      return
    }
    // FirebaseUserが存在すれば、userAdv, advAccountの作成画面へ遷移
    if (e.response.status === 401) return redirectToSetUpAccount()
    if (e.response.status === 400 && e.response.data.error.code === 'E1004') {
      console.log(e.response.data)
      redirectToSetUpAccount()
    }
  }

  const redirectToSetUpAccount = () => {
    snackActions.warning('サインアップ作業が途中です')
    if (router.pathname !== pagesPath.signup.account.$url().pathname) {
      router.push(pagesPath.signup.account.$url())
    }
  }

  // userAdvとadvAccountを取得してstateにセット
  const setupAccounts = async () => {
    let isSupport = false
    try {
      const userAdv = await getCurrentUserAdv() // userAdvの取得
      isSupport = userAdv.authority === 'support'
      setCurrentUserAdv(userAdv)
    } catch (e) {
      setCurrentUserAdv(null)
      setCurrentAdv(null) // userAdvの取得に失敗した場合は、advAccountも存在しないことが決まるため、advAccountの取得は行わずにnullにする
      handleAuthenticationError(e)
      return
    }
    // userAdvのが正常に取得できた場合のみ、advAccountの取得を開始する。
    // userAdvのみ存在し、advAccountがない場合は、サーバーやアプリ側で起こりうるため。
    // 逆にuserAdvはないが、advAccountが存在することはありえないため、userAdvの結果に応じてadvAccountの取得をする
    try {
      const advAccount = await getAdvAccount()
      if (isSupport) {
        // 代理ログインの場合は対象のAdvAccountのIDをセット
        proxyLogin.setProxyAdvAccountId(advAccount.id)
      }
      setCurrentAdv(advAccount)
    } catch (e) {
      setCurrentAdv(null)
      handleAuthenticationError(e)
    }
  }

  useEffect(() => {
    // FirebaseUserが存在していればそれをセットし、存在しなければ初期値のundefinedからnullに更新
    auth.onAuthStateChanged(async (user: FirebaseUser | null) => setCurrentUser(user))
  }, [])

  useEffect(() => {
    if (currentUser === undefined) return // FirebaseUser(currentUser)が初期値の場合(FirebaseUserの取得を試みていない場合)は何もしない

    if (currentUser === null) {
      // currentUserの取得を試みた結果、ユーザー存在しなかった場合(未ログイン or そもそもアカウントを作成していない場合)は、サインインページへリダイレクト
      setCurrentUserAdv(null)
      setCurrentAdv(null)
      redirectToSignIn()
    } else {
      // FirebaseUserが存在する場合は、userAdvとadvAccountを順番に取得し、stateにセットする
      setupAccounts()

      // pf apiを使う多くのケースでviewerが必要となるので、prefetchを試みる
      // setupAccountsと平行してInflatingAppLoaderが表示されている間に実行する
      prefetchViewer()
    }
  }, [currentUser])

  // 全てのアカウント情報の取得が成功している場合かつ、本番環境のみLogRockectとBugsnagのセットアップをする
  useEffect(() => {
    if (!currentUser || !currentUserAdv || !currentAdv) return
    setUserId(currentUserAdv.id)
    Bugsnag.setUser(`${currentUserAdv.id}`, currentUserAdv.email, currentUserAdv.name)

    setClarityCustomTags('user_adv_id', currentUserAdv.id?.toString() || 'anonymous')
    setClarityCustomTags('user_name', currentUserAdv.name || 'anonymous')
    setClarityCustomTags('adv_account_id', currentAdv.id.toString() || 'anonymous')
    setClarityCustomTags('company_name', currentAdv.companyName || 'anonymous')

    if (IS_PRODUCTION && currentAdv.companyName) {
      LogRocket.identify(String(currentUserAdv.id), {
        user_type: 'user_adv',
        name: currentUserAdv.name,
        staff_link: `https://staff.collatech.net/user_advs/${currentUserAdv.id}`,
        company_names: currentAdv.companyName,
      })
    }
  }, [currentUser, currentUserAdv, currentAdv])

  const canRender = () => {
    // 全てのアカウント情報が揃っていたら表示可能
    if (currentUser && currentUserAdv && currentAdv) return true

    // 認証ページであれば、読み込みがすべて完了すれば表示可能
    if (isAuthPage && currentUser !== undefined && currentUserAdv !== undefined && currentAdv !== undefined) {
      // ただしログイン後にadvAccount, userAdvの取得に失敗したときは、/signup/account以外を表示しない
      // redirectToSetUpAccountと対応する
      if (
        currentUser !== null &&
        (currentUserAdv === null || currentAdv === null) &&
        router.pathname !== pagesPath.signup.account.$url().pathname
      ) {
        return false
      }

      return true
    }

    return false
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentUserAdv,
        setCurrentUserAdv,
        currentAdv,
        setCurrentAdv,
        refetchCurrentAdv,
      }}
    >
      {canRender() ? props.children : <InflatingAppLoader />}
    </AuthContext.Provider>
  )
}
