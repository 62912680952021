import { CssBaseline, ThemeProvider } from '@mui/material'
import { ReactNode } from 'react'
import { theme } from 'utils/theme'

export const AppThemeProvider = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
)
