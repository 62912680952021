import TagManager from 'react-gtm-module'
import Router from 'next/router'
import { GOOGLE_ANALYTICS_ID, IS_STAGING } from './config'

export const eventPush = <K extends keyof EventMap>(event: K, data: EventMap[K]) => {
  if (IS_STAGING) {
    // staging環境ではFirebaseイベントを送らない（代理ログインテストとかを考慮）
    return false
  }

  // 定義上ではnullableではないがnullになるタイミングがあり、GoogleTagManagerの設定次第ではnull
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!window.gtag) {
    // 想定外
    return false
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!event) {
    console.warn('計測イベントにイベント名が指定されていません')
    return false
  }

  const dataLayer = {
    event,
    ...data,
    // location.pathnameとほとんど同じだが、queryが含まれたり微妙な違いがあるのでnext/routerを使う
    current_screen: Router.asPath,
  } as Record<string, unknown>

  window.gtag('event', event, dataLayer)
  console.debug(`FE: ${event} was sent. params are`, dataLayer)
  return true
}

export const setUserId = (userId: number | null) => {
  if (IS_STAGING) {
    // staging環境ではFirebaseイベントを送らない（代理ログインテストとかを考慮）
    return false
  }

  if (typeof TagManager === 'undefined') {
    console.warn(`TagManagerがundefinedです。ユーザIDのTagManagerへのセットをSkipします`)
  } else {
    // TagManagerにユーザIDをセットする
    TagManager.dataLayer({
      dataLayer: {
        uid: userId,
      },
    })

    console.debug(`FE: set user id to gtm ${userId}`)
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!window.gtag) {
    console.warn('window.tagがundefinedです。ユーザIDのGAへのセットをSkipします')
  } else {
    // GoogleAnalyticsにユーザIDをセットする
    window.gtag('config', GOOGLE_ANALYTICS_ID as string, {
      user_id: userId,
      send_page_view: false,
    })
    console.debug(`FE: set user id to ga ${userId}`)
  }
}

type ProviderType = 'email' | 'google' | 'apple' | ''

type CampaignEntriesSortKind = 'created_desc' | 'follower_desc' | 'expired_at_asc' | 'latest_accessed_at_desc'

type OptionGradeType = 'GRADE_1' | 'GRADE_2' | 'GRADE_3'

/* eslint-disable @typescript-eslint/ban-types */

type EventMap = {
  //#region オファー更新系
  /** 取引キャンセル依頼を申請した */
  send_abort_request: {
    offer_id: number
  }

  /** 要対応マークを付けた */
  add_reply_todo: {
    offer_id: number
  }

  /** 要対応マークを外した */
  remove_reply_todo: {
    offer_id: number
  }

  /** ピン留めした */
  add_pin_entry: {
    campaign_entry_id: number
  }

  /** ピン留めを解除した */
  remove_pin_entry: {
    campaign_entry_id: number
  }

  /** ソートを変更した */
  change_sort: {
    campaign_id: number | undefined
    /** ソート種別 */
    sort: string
  }

  /** 採用した */
  accept_campaign_entry: {
    campaign_entry_id: number
    /** DateTime: 最終ログイン */
    // latest_accessed_at: string
    latest_accesd_at: string | null | undefined
    /** Facebook認証済みか？ */
    has_authorized_facebook?: 0 | 1 | null
    /** 応募リストの何番目か */
    applicants_list_index: number | undefined
    /** 採用時点でのインフルエンサーのフォロワー数 */
    follower_count: number | undefined
    /** pin留めされた応募か */
    pinned: 0 | 1
    /** 採用したときのsort */
    sort: CampaignEntriesSortKind | undefined
  }

  /** ブロックした（永久不採用にした） */
  block_campaign_entry: {
    campaign_entry_id: number
  }

  /** 日程を承諾した */
  accept_schedule: {
    offer_id: number
  }

  /** 日程を提案した */
  propose_schedule: {
    offer_id: number
    /** 提案した日程の数 */
    schedule_count: number
  }

  /** 日程をおまかせした */
  ask_schedule: {
    offer_id: number
  }

  /** 完了報告を承認した */
  accept_report: {
    offer_id: number
  }

  /** 完了報告の書き直しを依頼した */
  reject_report: {
    offer_id: number
  }

  /** 完了報告を評価した */
  rate_satisfaction: {
    offer_id: number
    /** 完了報告の評価 */
    value: number
  }

  /** 一括で採用した */
  accept_entry_in_bulk: {
    /** 採用に成功したオファー数 */
    count: number
    loaded_count: number // ロードされた応募数
  }

  /** 来店を報告した */
  inform_visit: {
    offer_id: number
  }

  /** 取引キャンセルを取り下げた */
  withdraw_cancel: {
    offer_id: number
  }

  /** 取引キャンセルに同意した */
  accept_cancel: {
    offer_id: number
  }

  /** 取引キャンセルに同意しなかった */
  decline_cancel: {
    offer_id: number
  }

  /** 日程調整のやり直しを提案した */
  reschedule: {
    offer_id: number
  }

  /** キャンペーンを募集停止した */
  close_campaign: {
    campaign_id: number
    /** 募集停止理由 */
    reason: string
  }

  /** 修正待ち状態のキャンペーンを編集して審査状態にした */
  edit_campaign: {
    campaign_id: number
  }

  /** 審査状態のキャンペーンを取り下げた */
  withdraw_campaign: {
    campaign_id: number
  }

  /** オファー一覧をフィルタした */
  filter_offers: {
    /** filter_template_id */
    filter_kind: number
  }

  withdraw_campaign_review: {
    campaign_id: number
  }
  //#endregion

  //#region キャンペーン更新系
  create_campaign: {
    platform: 'app' | 'web' | 'unknown'
    page:
      | 'create_method' // キャンペーンの作成方法を選択した
      | 'basic_info' // キャンペーン作成を始めた
      | 'checklist' // キャンペーン作成画面のchecklistに進んだ
      | 'select_plan'
      | 'service_contents' // キャンペーン作成画面のservice_contentsに進んだ
      | 'getting_posted' // キャンペーン作成画面のgetting_postedに進んだ
      | 'detailed_info' // キャンペーン作成画面のdetailed_infoに進んだ
      | 'confirmation' // キャンペーン作成画面のconfirmationに進んだ
      | 'create_success' // キャンペーン作成画面に成功した
      | 'create_failure' // キャンペーン作成画面に失敗した
    uuid: string
  }
  //#endregion

  //#region 広告主更新系
  /** アカウント作成を開始した */
  start_sign_up: {}
  /** メールアドレスで新規登録するためのメールを送信した（メールアドレス認証のみ） */
  send_sign_up_link: { method: ProviderType }
  /** メールに含まれるURLをタップしてサービスに戻ってきた（メールアドレス認証のみ） */
  open_sign_up_link: { method: ProviderType }
  /** FirebaseUserを作成した */
  create_firebase_user: { method: ProviderType }
  /** UserAdvを作成した */
  create_user_adv: {}
  /** AdvAccountを作成した */
  create_adv_account: {}
  /** LINE Official Account の追加ボタンを押した */
  add_line_account: {}
  /** ログインした */
  login: { method: ProviderType }
  /** SignUpした */
  sign_up: {}
  /** SignOutした */
  sign_out: {}
  //#endregion

  //#region プラン・オプション系

  /** プロモーションコードの利用に成功した */
  use_promotion_code: {
    promotion_code: string
  }

  /** プロモーションコードの利用に失敗した */
  use_promotion_code_error: {
    promotion_code: string
    error_reason: string
  }

  /** プラン契約ダイアログを開いた */
  open_contract_plan_dialog: {
    plan_id: number
    plan_name: string
    /** 表示順位(0始まり) */
    plan_display_order: number
    /** プロモコード利用以外の場合はnull */
    promotion_code: string | null
  }

  /** プラン契約ダイアログを閉じた */
  close_contract_plan_dialog: {
    plan_id: number
    plan_name: string
    /** 表示順位(0始まり) */
    plan_display_order: number
    /** プロモコード利用以外の場合はnull */
    promotion_code: string | null
  }

  /** プランの契約確定ボタンを押下した */
  click_contract_plan: {
    plan_id: number
    plan_name: string
    /** 表示順位(0始まり) */
    plan_display_order: number
    /** プロモコード利用以外の場合はnull */
    promotion_code: string | null
  }

  /** プランの契約に成功した */
  contract_plan: {
    plan_id: number
    plan_name: string
    /** 表示順位(0始まり) */
    plan_display_order: number
    /** プロモコード利用以外の場合はnull */
    promotion_code: string | null
  }

  /** プランの契約に失敗した */
  contract_plan_error: {
    plan_id: number
    plan_name: string
    /** 表示順位(0始まり) */
    plan_display_order: number
    /** プロモコード利用以外の場合はnull */
    promotion_code: string | null
    error_reason: string
  }

  /** プラン契約完了ダイアログを閉じた */
  close_contract_plan_success_dialog: {
    plan_id: number
    plan_name: string
    /** 表示順位(0始まり) */
    plan_display_order: number
  }

  /** オプション契約ダイアログを開いた */
  open_subscribe_option_dialog: {
    option_id: number
    option_group: string
    option_grade: OptionGradeType
    /** 表示順位(0始まり) */
    option_group_display_order: number
    /** 表示順位(0始まり) */
    option_grade_display_order: number
  }

  /** オプション契約ダイアログを閉じた */
  close_subscribe_option_dialog: {
    option_id: number
    option_group: string
    option_grade: OptionGradeType
    /** 表示順位(0始まり) */
    option_group_display_order: number
    /** 表示順位(0始まり) */
    option_grade_display_order: number
  }

  /** オプションの契約確定ボタンを押下した */
  click_subscribe_option: {
    option_id: number
    option_group: string
    option_grade: OptionGradeType
    /** 表示順位(0始まり) */
    option_group_display_order: number
    /** 表示順位(0始まり) */
    option_grade_display_order: number
  }

  /** オプションの契約に成功した */
  subscribe_option: {
    option_id: number
    option_group: string
    option_grade: OptionGradeType
    /** 表示順位(0始まり) */
    option_group_display_order: number
    /** 表示順位(0始まり) */
    option_grade_display_order: number
  }

  /** オプションの契約に失敗した */
  subscribe_option_error: {
    option_id: number
    option_group: string
    option_grade: OptionGradeType
    /** 表示順位(0始まり) */
    option_group_display_order: number
    /** 表示順位(0始まり) */
    option_grade_display_order: number
    error_reason: string
  }

  /** オプション契約完了ダイアログを閉じた */
  close_subscribe_option_success: {
    option_id: number
    option_group: string
    option_grade: OptionGradeType
    /** 表示順位(0始まり) */
    option_group_display_order: number
    /** 表示順位(0始まり) */
    option_grade_display_order: number
  }

  /** オプション解約ダイアログを開いた */
  open_unsubscribe_option_dialog: {
    option_id: number
    option_group: string
    option_grade: OptionGradeType
    /** 表示順位(0始まり) */
    option_group_display_order: number
    /** 表示順位(0始まり) */
    option_grade_display_order: number
  }

  /** オプション解約ダイアログを閉じた */
  close_unsubscribe_option_dialog: {
    option_id: number
    option_group: string
    option_grade: OptionGradeType
    /** 表示順位(0始まり) */
    option_group_display_order: number
    /** 表示順位(0始まり) */
    option_grade_display_order: number
  }

  /** オプションの解約確定ボタンを押下した */
  click_unsubscribe_option: {
    option_id: number
    option_group: string
    option_grade: OptionGradeType
    /** 表示順位(0始まり) */
    option_group_display_order: number
    /** 表示順位(0始まり) */
    option_grade_display_order: number
  }

  /** オプションの解約に成功した */
  unsubscribe_option: {
    option_id: number
    option_group: string
    option_grade: OptionGradeType
    /** 表示順位(0始まり) */
    option_group_display_order: number
    /** 表示順位(0始まり) */
    option_grade_display_order: number
  }

  /** オプションの解約に失敗した */
  unsubscribe_option_error: {
    option_id: number
    option_group: string
    option_grade: OptionGradeType
    /** 表示順位(0始まり) */
    option_group_display_order: number
    /** 表示順位(0始まり) */
    option_grade_display_order: number
    error_reason: string
  }

  /** 応募詳細でインサイトオプション未契約時の契約煽りボタンを押した */
  tap_insight_promotion: {}

  /** 応募詳細でインサイトオプション契約時のアプデ煽りボタンを押した */
  tap_insight_update: {
    insight_option_grade: string
  }

  /** メニューのオプション一覧への導線ボタンを押した */
  tap_option_menu: {}

  /** ビギナーのコンテンツ一覧から詳細ページへのリンクをクリックした */
  view_beginner: {
    beginner_post_id: number
    beginner_post_title: string
  }
  //#endregion
}
